<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="Ball-Climbing-Dot"
>
<div class="la-ball-fussion la-2x">
	<div></div>
	<div></div>
	<div></div>
	<div></div>
</div>
</ngx-spinner>
<app-navbar *ngIf="!loading_user && show_header_footer"></app-navbar>
<app-shimmer type="load-header" *ngIf="loading_user"></app-shimmer>
<router-outlet></router-outlet>
<app-footer *ngIf="!loading_user && show_header_footer"></app-footer>
<app-shimmer type="load-footer" *ngIf="loading_user"></app-shimmer>

<ngx-scrolltop></ngx-scrolltop>