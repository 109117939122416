import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Setting } from 'src/app/models/setting-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  entity: string = "/setting";

  public settingSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('settings')!));

  public get settings(): any {

    let show_setting = (localStorage.getItem('show_settings')) ? localStorage.getItem('show_settings') : "0";
    return { value : new Setting(this.settingSubject.value) , show: show_setting };
  }

  close_settings(){
    localStorage.setItem('show_settings','0');
  }

  constructor(private http: HttpClient) { }

  get(): Observable<any> {
    return this.http.get(environment.api + this.entity, httpOptions);
  }
}
