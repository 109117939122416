import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  entity: string = "/users";

  constructor(private http: HttpClient) { }

  get(token: string): Observable<any> {
    return this.http.get(environment.api + '/users/me?populate=profile.avatar,stripe,role'
      , { headers: { Authorization: 'Bearer ' + token, "content-type": "application/json" } });
  }

  change_password(values: any, token: string) {
    return this.http.post(environment.api + "/custom/change-password", values, { headers: { Authorization: 'Bearer ' + token, "content-type": "application/json" } });
  }


}
