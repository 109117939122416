<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span class="top-title">FOR A BETTER FUTURE</span>
                    <h1>A Course Is Essential For Building A <span>Career</span></h1>
                    <p>The experience of our instructors benefits your career </p>
                    <div class="search-bar">
                        <a routerLink="/courses" class="default-btn">Find Courses </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-img banner-img-2">
                    <img src="assets/images/banner/banner-img-2.png" alt="Image">
                </div>
            </div>
        </div>
        <div class="new-additions online-student">
            <div class="new-brand">
                <img src="assets/images/students.png" alt="Image">
            </div>
            <h6>Total Online Student</h6>
            <h3>125,000 +</h3>
        </div>
    </div>
    <div class="banner-bg-shape"><img src="assets/images/banner/banner-bg-shape.png" alt="Image"></div>
</div>