<div *ngIf="!loading" class="single-course-item" data-toggle="tooltip" data-placement="bottom"
    title="{{ formation.title }}">
    <ng-template #courseContent>
        <div class="visible-black-bg" *ngIf="formation.is_learning"></div>
        <div class="progress-circle over50 p100 position-absolute" *ngIf="formation.is_learning">
            <div class="left-half-clipper">
                <div class="value-bar-custom"></div>
            </div>
        </div>
        <div class="progress-circle {{ (formation.pourcentage >= 50) ? 'over50' : '' }} p{{ formation.pourcentage }} position-absolute"
            *ngIf="formation.is_learning">
            <span>{{ formation.pourcentage }}%</span>
            <div class="left-half-clipper">
                <div class="first50-bar"></div>
                <div class="value-bar"></div>
            </div>
        </div>

        <img class="img-of-course" alt="Image" [defaultImage]="defaultImage" [lazyLoad]="formation.thumbnail"
            [useSrcset]="true">
        <span *ngIf="!formation.is_draft" class="levels">{{ formation.level }}</span>
        <span class="price" *ngIf="formation.price > 0 && !formation.is_draft && !formation.is_in_purchase">
            <span *ngIf="formation.has_promo" class="text-decoration-line-through"
                style="font-size: 13px;margin-right: 5px;">{{ formation.price }}€</span>
            <span *ngIf="formation.has_promo" style="font-size: 20px !important;">{{ (formation.new_price > 0) ?
                (formation.new_price + "€") : "Gratuit" }}</span>
            <span *ngIf="!formation.has_promo" style="font-size: 20px !important;">{{ formation.price }}€</span>
        </span>
        <span class="price" *ngIf="formation.price == 0 && !formation.is_draft && !formation.is_in_purchase">Gratuit</span>
    </ng-template>
    <ng-container *ngIf="!formation.is_draft; else notDraft">
        <a [routerLink]="'/courses-details/' + formation.slug" class="courses-img position-relative">
            <ng-container *ngTemplateOutlet="courseContent"></ng-container>
        </a>
    </ng-container>
    <ng-template #notDraft>
        <span class="courses-img position-relative">
            <ng-container *ngTemplateOutlet="courseContent"></ng-container>
        </span>
    </ng-template>
    <div class="courses-content">
        <div style="height: 60px;">
            <h3 [ngClass]="{'text-center':formation.is_draft}">
                <ng-container *ngIf="!formation.is_draft; else notDraftTitle">
                    <a [routerLink]="'/courses-details/' + formation.slug">
                        {{ formation.title }}
                    </a>
                </ng-container>
                <ng-template #notDraftTitle>
                    <span>{{ formation.title }}</span>
                </ng-template>
            </h3>
        </div>
        <div *ngIf="!formation.is_draft" class="pb-2">
            <p class="former-avatar">
                <img class="miniature-profile" alt="Image" [defaultImage]="defaultImage"
                    [lazyLoad]="formation.profile.thumbnail" [useSrcset]="true">
                {{ formation.profile.short_full_name() }}
            </p>


            <ng-template #t let-fill="fill">
                <i class="ri-star-fill">
                    <i *ngIf="fill > 0" class="ri-star-fill filled" [style.width.%]="fill"></i>
                </i>
            </ng-template>
            <ngb-rating [readonly]="true" [starTemplate]="t" [max]="5" [rate]="note" aria-label="Rating"></ngb-rating>
            <span> {{note.toFixed(1)}} ({{formation.reviews.length}})</span>

            <ul class="status d-flex justify-content-between align-items-center mt-2 mb-3" *ngIf="show_details">
                <li>
                    <i class="ri-time-line"></i>
                    {{ formation.duration }}
                </li>
                <li *ngIf="formation.count_vues != 0">
                    <i class="ri-eye-fill"></i>
                    {{ formation.count_vues }} Vues
                </li>
            </ul>

            <div style="margin-top:10px;" *ngIf="!show_details"></div>
            <button type="button" class="course-btn-primary w-100 mb-2" *ngIf="!show_details"
                (click)="generate_certificat()"><i class="fa fa-download"></i>Télécharger l'attestation</button>
            <button (click)="addToCart(formation.id)" type="button" class="course-btn-secondary w-100 mb-2"
                *ngIf="formation.price != -1 && !formation.is_in_purchase && !checkIfInCart(formation.id)"><i
                    class="ri-shopping-cart-line"></i>Ajouter au panier</button>
            <button [routerLink]="'/cart'" type="button" class="course-btn-primary w-100 mb-2"
                *ngIf="formation.price != -1 && !formation.is_in_purchase && checkIfInCart(formation.id)"><i
                    class="ri-shopping-cart-line"></i>Voir mon panier</button>
            <button type="button" class="course-btn-primary w-100 mb-2"
                *ngIf="formation.price != -1 && formation.is_in_purchase"
                [routerLink]="'/courses-details/' + formation.slug">Découvrir la formation</button>
        </div>
    </div>
</div>