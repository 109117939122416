<div class="row" *ngIf="type == 'col-2'">
    <ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'150px'" class="col-md-2"
        *ngFor="let i of arrayOne(size)"></ngx-shimmer-loading>
</div>
<div class="row" *ngIf="type == 'col-3'">
    <ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'200px'" class="col-md-3"
        *ngFor="let i of arrayOne(size)"></ngx-shimmer-loading>
</div>
<div class="row" *ngIf="type == 'col-course-3'">
    <div class="col-xl-3 mb-5" *ngFor="let i of arrayOne(size)">
        <ngx-shimmer-loading [shape]="'rect'" [height]="'200px'" [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'70%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'50%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'100%'"></ngx-shimmer-loading>
    </div>
</div>
<div class="row" *ngIf="type == 'col-course-4'">
    <div class="col-xl-4 mb-5" *ngFor="let i of arrayOne(size)">
        <ngx-shimmer-loading [shape]="'rect'" [height]="'200px'" [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'70%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'50%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'100%'"></ngx-shimmer-loading>
    </div>
</div>
<div class="row p-2" *ngIf="type == 'col-course-cart'">
    <div class="d-lg-flex py-2 border-bottom" *ngFor="let i of arrayOne(size)">
        <ngx-shimmer-loading [shape]="'rect'" [height]="'250px'" [width]="'100%'" class="pt-2 mb-3 d-block d-lg-none"></ngx-shimmer-loading>
        <ngx-shimmer-loading [shape]="'rect'" [height]="'150px'" [width]="'200px'" class="pt-2 me-3 d-none d-lg-block"></ngx-shimmer-loading>
        <div class="w-100">
            <div class="d-flex justify-content-between">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" class="w-50"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'30px'" [width]="'100px'"></ngx-shimmer-loading>
            </div>
            <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'30%'"></ngx-shimmer-loading><br>
            <ngx-shimmer-loading [shape]="'rect'" [height]="'25px'" [width]="'20%'"></ngx-shimmer-loading><br>
            <ngx-shimmer-loading class="mt-3" [shape]="'rect'" [height]="'25px'" [width]="'20%'"></ngx-shimmer-loading>
        </div>
    </div>
</div>
<div class="row" *ngIf="type == 'col-6'">
    <ngx-shimmer-loading [shape]="'rect'" [height]="'200px'" [width]="'100%'" class="col-md-6"
        *ngFor="let i of arrayOne(size)"></ngx-shimmer-loading>
</div>
<div class="row" *ngIf="type == 'button-col-6'">
    <ngx-shimmer-loading [shape]="'rect'" [height]="'50px'" [width]="'100%'" class="col-md-6"
        *ngFor="let i of arrayOne(size)"></ngx-shimmer-loading>
</div>
<div class="row" *ngIf="type == 'button-col-12'">
    <ngx-shimmer-loading [shape]="'rect'" [height]="'50px'" [width]="'100%'" class="col-md-12"
        *ngFor="let i of arrayOne(size)"></ngx-shimmer-loading>
</div>

<div class="row" *ngIf="type == 'banner'">
    <ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'400px'" [width]="'100%'"></ngx-shimmer-loading>
</div>

<div class="row justify-content-md-center" *ngIf="type == 'pricing'">
    <ngx-shimmer-loading class="col-lg-4 col-md-6" [shape]="'rect'" [height]="'500px'"
        [width]="'100%'"></ngx-shimmer-loading>
    <ngx-shimmer-loading class="col-lg-4 col-md-6" [shape]="'rect'" [height]="'500px'"
        [width]="'100%'"></ngx-shimmer-loading>
    <ngx-shimmer-loading class="col-lg-4 col-md-6" [shape]="'rect'" [height]="'500px'"
        [width]="'100%'"></ngx-shimmer-loading>
</div>

<ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'90px'" [width]="'100%'"
    *ngIf="type == 'load-header'"></ngx-shimmer-loading>

<ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'300px'" [width]="'100%'"
    *ngIf="type == 'load-footer'"></ngx-shimmer-loading>