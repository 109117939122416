<!-- Footer -->
<footer class="footer-area bg-color-05122d pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a href="/" class="logo">
                        <img src="assets/images/white-logo.png" alt="Image">
                    </a>
                    <p>LENIDIT propose des formations IT en ligne pour les francophones, offrant un apprentissage flexible et complet depuis chez soi.</p>
                    <ul class="address">
                        <li>
                            <i class="ri-phone-fill"></i>
                            <a href="tel:+33606799090">+33 6 06 79 90 90</a>
                        </li>
                        <li>
                            <i class="ri-mail-fill"></i>
                            <a href="mailto:support@edon.com">contact@lenidit.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/profile.php?id=100067162000194" target="_blank"
                                aria-label="facebook"><i class="ri-facebook-fill"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCh5QVK5blL6QtzOlyQE0lpg" target="_blank"
                                aria-label="youtube"><i class="ri-youtube-fill"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/15816851" target="_blank" aria-label="linkedin"><i
                                    class="ri-linkedin-box-fill"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>Menu</h3>
                    <ul class="import-link">
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/courses">Cours</a></li>
                        <li><a href="/tarification">Tarification</a></li>
                        <li><a href="/mentions-legales">Mentions Légales</a></li>
                        <li><a href="/contact">Contactez-nous</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>Catégories</h3>
                    <ul class="import-link">
                        <li *ngFor="let category of categories"><a href="/category/{{ category.slug }}">{{ category.name
                                }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Nos applications</h3>
                    <div class="mb-0"><a href="#"><img class="download-app" src="assets/images/appstore.svg"
                                alt="Image"></a></div>
                    <div class="">
                        <a href="#"><img class="download-app" src="assets/images/google-play.svg" alt="Image"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- Copyright -->
<div class="copy-right-area bg-color-05122d">
    <div class="container">
        <div class="copy-right-wrap d-block d-md-flex">
            <div>
                <p>© 2024 LENID IT TOUS DROITS SONT RÉSERVÉS</p>
            </div>
            <div>
                <span>Paiement sécurisé</span>
                <img src="assets/images/mastercard-logo.svg" alt="Image">
                <img src="assets/images/visa-logo.svg" alt="Image">
            </div>
        </div>
    </div>
</div>