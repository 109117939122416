import { Component, Input, OnInit } from '@angular/core';
import { Formation } from 'src/app/models/formation-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-card-horizontal',
  templateUrl: './course-card-horizontal.component.html',
  styleUrls: ['./course-card-horizontal.component.scss']
})
export class CourseCardHorizontalComponent implements OnInit {
  @Input() formation!: Formation;
  @Input() loading!: boolean;
  defaultImage = environment.defaultImage;
  constructor() { }

  ngOnInit(): void {
  }

}
