import { environment } from "src/environments/environment";
import { AwsService } from "../services/aws/aws.service";

export class Profile {
  id!: number;
  first_name: string = "";
  last_name: string = "";
  phone!: string;
  address!: string;
  city!: string;
  title!: string;
  web_site!: string;
  email!: string;
  speciality!: string;
  description!: string;
  date_of_birthday!: string;
  user!: number;
  avatar!: any;
  thumbnail: string = "";
  large_avatar: string = "";
  postal_code!: string;
  short_address: string = "";
  country: string = "";


  constructor(data?: Partial<Profile>) {
    if (data) {
      Object.assign(this, data);
      if (data.id !== undefined && this.thumbnail == "") {
        if (data.avatar) {
          this.thumbnail = AwsService.generate_link( (data.avatar.formats.thumbnail != null) ? data.avatar.formats.thumbnail.url : data.avatar.url);
          this.large_avatar = AwsService.generate_link(data.avatar.url);
        } else {
          this.thumbnail = environment.default_avatar;
          this.large_avatar = environment.default_cover;
        }
      }
    }

  }

  full_name() {
    return this.first_name + " " + this.last_name;
  }

  short_full_name() {
    return (((this.first_name + " " + this.last_name).length > 25) ? (this.first_name + " " + this.last_name).substring(0, 25) + "..." : (this.first_name + " " + this.last_name)) ;
  }

}
