
export class Setting {
    show_price: boolean = false;
    show_vues: boolean = false;
    show_top_bar: boolean = false;
    message_top_bar: string = "";
    bg_top_bar: string = "";
    color_top_bar: string = "";
    start_top_bar: string = "";
    end_top_bar: string = "";

    constructor(data?: Partial<Setting>) {
        if (data) {
            Object.assign(this, data);
        }
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;
        return obj;
    }

}
