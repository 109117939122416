import { environment } from 'src/environments/environment';
import { AwsService } from '../services/aws/aws.service';
import { Formation } from './formation-model';

export class Category {
    id?: number;
    name?: string;
    slug?: string;
    image_category?: any;
    thumbnail: string = "";
    formations: Array<Formation> = [];
    description: string = "";
    meta_title_google: string = "";
    meta_description_google: string ="";

    constructor(data: Partial<Category>) {
        Object.assign(this, data);
        if (data.id !== undefined) {
            if (this.thumbnail == "") {
                if (data.image_category) {
                    this.thumbnail = AwsService.generate_link((data.image_category.formats != null) ? ((data.image_category.formats.thumbnail != null) ? data.image_category.formats.thumbnail.url : data.image_category.url) : data.image_category.url);
                } else {
                    this.thumbnail = environment.default_cover;
                }
            }
        }
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;
        obj["image_category"] = item.attributes.image_category.data.attributes;
        return obj;
    }

}
