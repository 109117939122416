import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pricing } from 'src/app/models/pricing-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  entity: string = "/pricings";
  public pricingsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('pricings')!));

  constructor(private http: HttpClient) { }

  public get pricings(): any {

    let array: Array<Pricing> = [];

    if (this.pricingsSubject.value != null) {
      this.pricingsSubject.value.forEach((item: any) => {
        let pricing = new Pricing(item);
        array.push(pricing);
      });
    }

    return array;
  }

  get(): Observable<any> {
    return this.http.get(environment.api + this.entity, httpOptions);
  }

  send_email(values: any) {
    return this.http.post(environment.api + "/custom/send-email", values, { headers: { "content-type": "application/json" } });
  }

}
