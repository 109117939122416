import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Formation } from 'src/app/models/formation-model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart: string[] = [];
  private cartSubject = new BehaviorSubject<string[]>([]);

  cart$ = this.cartSubject.asObservable();

  constructor() {
    this.loadCart();
  }

  addToCart(item: string) {
    const existingItem = this.cart.find(Formation => Formation === item);
    if (existingItem) {
      Swal.fire("Attention", "Cette formation existe déjà dans la carte !", "info");
    } else {
      this.cart.push(item);
    }
    this.updateCart();
  }

  removeFromCart(itemId: string) {
    this.cart = this.cart.filter(Formation => Formation !== itemId);
    this.updateCart();
  }

  cleatCart() {
    this.cart = [];
    this.updateCart();
  }

  private updateCart() {
    this.saveCart();
    this.cartSubject.next(this.cart);
  }


  private saveCart() {
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  countItems(): number {
    return this.cart.length;
  }

  checkIfExist(itemId: string): boolean {
    return this.cart.some(item => item === itemId);
  }

  private loadCart() {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.cart = JSON.parse(savedCart);
      this.cartSubject.next(this.cart);
    }
  }


}
