<header class="header-area">
    <!-- TopBar -->
    <div class="w-100 text-center p-3 top-bar-alert bg-danger">
        <p class="text-white">Vous êtes connecté en tant qu'administrateur <button class="btn btn-primary pointer"
                (click)="desconnect_admin()">Déconnecter</button></p>
    </div>
    <div class="w-100 text-center p-3 top-bar-alert" *ngIf="!loading_settings && show_settings"
        [ngStyle]="setBgTopBar()">
        <p [ngStyle]="setMsgTopBar()">{{ settings.message_top_bar }}</p>
        <p [ngStyle]="setMsgTopBar()"><strong>Temps restant :</strong> {{ time_left_value }}</p>
        <span class="icon-close" (click)="closeTopBar()"><i class="fa fa-close"></i></span>
    </div>
    <!-- Navbar -->
    <div class="navbar-area" stickyClass="sticky-box-shadow" ngStickyNav *ngIf="!loading">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a (click)="classApplied=false" class="navbar-brand" [routerLink]="['/']" (click)="scrollToTop()"><img
                        src="assets/images/logo.png" alt="logo"></a>
                <div class="d-flex">
                    <ul class="nav-item">
                        <button (click)="classApplied && toggleClass()" routerLink='/cart'
                            class="navbar-toggler pe-3 bg-white cart-icon lh-3" type="button" aria-label="cart">
                            <i class="fa fa-cart-shopping fs-4 position-relative pe-1 ">
                                <span class="cart" *ngIf="cartItems.length > 0">{{cartItems.length}}</span>
                            </i>
                        </button>
                    </ul>
                    <button class="navbar-toggler" type="button" (click)="toggleClass()" aria-label="Menu burger">
                        <span class="burger-menu">
                            <span class="top-bar"></span>
                            <span class="middle-bar"></span>
                            <span class="bottom-bar"></span>
                        </span>
                    </button>
                </div>

                <div class="collapse navbar-collapse" style="justify-content: space-between;">
                    <ul class="navbar-nav">
                        <li class="nav-item d-lg-none d-block" *ngIf="!currentUser.isEmpty">
                            <img src="{{ currentUser.profile.thumbnail }}"
                                style="object-fit: cover;height: 50px;width: 50px;border-radius: 50%;margin-right: 10px;"
                                alt="Image">
                            <span>{{ currentUser.full_name() }}</span>
                            <ul class="dropdown-menu">
                                <li (click)="toggleClass()" class="nav-item"><a routerLink="/mon-profil"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Mon
                                        profil</a></li>
                                <li (click)="toggleClass()" class="nav-item"><a routerLink="/mon-apprentissage"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Mon apprentissage</a></li>
                                <li (click)="toggleClass()" class="nav-item"><a routerLink="/mes-abonnements"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Mes
                                        abonnements</a></li>
                                <li (click)="toggleClass()" class="nav-item"><a routerLink="/mes-moyens-paiement"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Mes
                                        moyens de paiement</a></li>
                                <li (click)="toggleClass()" class="nav-item"><a routerLink="/mes-factures"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Mes factures</a></li>
                                <li class="nav-item" (click)="toggleClass()"><a class="nav-link" href="#"
                                        onclick="javascript:void(0)" (click)="logout()">Se déconnecter</a></li>
                            </ul>
                        </li>
                        <li class="nav-item" (click)="toggleClass()">
                            <a href="#" onclick="javascript:void(0)" class="nav-link dropdown-toggle">CATÉGORIES<i
                                    class="ri-arrow-down-s-line"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let category of categories"><a
                                        href="/category/{{ category.slug }}" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">{{
                                        category.name }}</a></li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-options">
                        <form [formGroup]='searchForm'>
                            <input name="searchInput" class="form-control pe-5" type='text' [focusFirst]="false"
                                (selectItem)="select($event)" [ngbTypeahead]="search" [inputFormatter]="formatter"
                                [resultFormatter]="formatter" placeholder="Trouver une formation..."
                                formControlName="searchInput">
                            <button (click)="goToSearchPage()" class="btn search-btn" type="submit"
                                aria-label="Recherche"><i class="ri-search-line"></i></button>
                        </form>
                    </div>
                    <div class="d-lg-flex">
                        <ul class="navbar-nav">
                            <li class="nav-item tarification" (click)="toggleClass()">
                                <a href="/tarification" routerLinkActive="active">Tarification</a>
                            </li>
                        </ul>
                        <div class="others-options">
                            <div *ngIf="!currentUser.isEmpty"
                                class="d-lg-flex d-none justify-content-between align-items-center header-right-content">
                                <ul class="navbar-nav">
                                    <li class="nav-item" (click)="toggleClass()">
                                        <a href="/mon-profil" class="nav-link dropdown-toggle custom-profile">
                                            <img src="{{ currentUser.profile.thumbnail }}" alt="Image"
                                                style="height: 45px; margin-right: 10px;">
                                            <span>{{ currentUser.full_name() }}</span>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a class="nav-link" href="/mon-profil"><i
                                                        class="ri-user-line"></i> Mon profil</a></li>
                                            <li class="nav-item"><a class="nav-link" href="/mes-achats"><i
                                                        class="fa fa-shopping-cart"></i> Mes achats</a></li>
                                            <li class="nav-item"><a class="nav-link" href="/mon-apprentissage"><i
                                                        class="ri-book-open-line"></i> Mon apprentissage</a></li>
                                            <li class="nav-item"><a class="nav-link" href="/mes-abonnements"><i
                                                        class="ri-checkbox-multiple-line"></i> Mes abonnements</a></li>
                                            <li class="nav-item"><a class="nav-link" href="/mes-moyens-paiement"><i
                                                        class="ri-bank-card-2-line"></i> Mes moyens de paiement</a></li>
                                            <li class="nav-item"><a class="nav-link" href="/mes-factures"><i
                                                        class="ri-bill-line"></i> Mes factures</a></li>
                                            <li class="nav-item"><a class="nav-link" href="#"
                                                    onclick="javascript:void(0)" (click)="logout()"><i
                                                        class="ri-logout-circle-r-line"></i> Se déconnecter</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="currentUser.isEmpty"
                                class="d-lg-flex justify-content-between align-items-center header-right-content mt-lg-4 buttons-navbar">
                                <a (click)="toggleClass()" routerLink="/login" class="default-btn-border mx-1">
                                    <i class="ri-login-circle-line"></i>
                                    Connexion
                                </a>
                                <a (click)="toggleClass()" routerLink="/register" class="default-btn mx-1">
                                    <i class="ri-lock-line"></i>
                                    Inscription
                                </a>
                            </div>
                        </div>
                        <ul class="navbar-nav d-lg-block d-none" *ngIf="!loading_settings && settings.show_price">
                            <li class="nav-item position-relative">
                                <button [ngClass]="{'lh-3':!currentUser.isEmpty}" class="cart-icon pe-3 bg-white"
                                    type="button" aria-label="cart" (click)="toggleCartDescription()">
                                    <i class="fa fa-cart-shopping fs-4 position-relative pe-1 ">
                                        <span class="cart" *ngIf="cartItems.length > 0">{{cartItems.length}}</span>
                                    </i>
                                </button>
                                <div class="cart-description position-absolute p-3 rounded shadow bg-light z-1"
                                    [ngClass]="{'d-none': isHidden}">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h4 class="mt-2">PANIER</h4>
                                        <h6 class="">{{cartItems.length}} produit<span
                                                *ngIf="cartItems.length>0">s</span></h6>
                                    </div>
                                    <ul class="list-group mb-3">
                                        <li class="list-group-item d-flex justify-content-between lh-sm"
                                            *ngFor="let formation of cartItems | slice:0:4">
                                            <div>
                                                <h6 class="my-0">{{formation.title}}</h6>
                                                <small
                                                    class="text-body-secondary">{{formation.very_short_description}}</small>
                                            </div>
                                            <div class="d-flex">
                                                <span class="fw-bold"
                                                    [ngClass]="{'text-decoration-line-through text-danger fs-6':formation.has_promo}">{{formation.price}}€</span>
                                                <span class="text-body-secondary fw-bold fs-4"
                                                    *ngIf="formation.has_promo">{{formation.new_price}}€</span>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex lh-sm" *ngIf="cartItems.length>4">
                                            <i class="fa fa-info-circle me-1"></i><small class="my-0 text-primary">Voir
                                                les autres formation dans le panier</small>
                                        </li>
                                        <li class="list-group-item d-flex lh-sm" *ngIf="cartItems.length==0">
                                            <i class="fa fa-info-circle me-1"></i><small class="my-0 text-primary">Votre
                                                panier est vide.</small>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between"
                                            *ngIf="cartItems.length>0">
                                            <span>Total</span>
                                            <strong>{{totalPrice}}€</strong>
                                        </li>
                                    </ul>
                                    <button (click)="toggleCartDescription()" routerLink='/cart' type="button"
                                        class="default-btn w-100">Voir mon
                                        panier</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</header>