import { Injectable } from '@angular/core';
import {
  Resolve
} from '@angular/router';
import { User } from 'src/app/models/user-model';
import { AuthService } from '../auth/auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<boolean> {
  constructor(private authService: AuthService, private userService: UserService) {

  }
  resolve(): any {
    var mypromise = new Promise((resolve, reject) => {
      this.userService.get(this.authService.currentUserValue.token).subscribe(user=>{        
        resolve(user);
      },err=>{
        this.authService.logout();
        reject(null);
      })
    });
    return mypromise;
  }
}
