import { Profile } from "./profile-model";
import { environment } from 'src/environments/environment';
import { Module } from "./module-model";
import { Chapter } from "./chapter-model";
import { Review } from "./review-model";
import { Category } from "./category-model";
import { AwsService } from "../services/aws/aws.service";
import { DateFormatterService } from "../services/date-formatter/date-formatter.service";

export class Formation {
  id: string = "";
  title: string = "";
  description?: string;
  short_description: string = "";
  very_short_description: string = "";
  level?: string;
  duration?: string;
  price: number = -1;
  couverture?: any;
  thumbnail: string = "";
  slug?: string;
  meta_title_google: string = "";
  meta_description_google: string = "";
  profile: Profile = new Profile({});
  modules: Array<Module> = [];
  reviews: Array<Review> = [];
  categories: Array<Category> = [];
  nbr_modules: number = 0;
  nbr_chapter: number = 0;
  count_duration: string = "";
  count_vues: number = 0;
  active: boolean = false;
  pourcentage: number = -1;
  is_learning: boolean = false;
  support: string = "";
  certificate: string = "";
  is_draft: boolean = false;
  has_promo: boolean = false;
  new_price: number = -1;
  is_in_purchase: boolean = false;

  constructor(data?: Partial<Formation>) {
    if (data) {
      Object.assign(this, data);
      if (data.id !== undefined) {

        this.duration = DateFormatterService.toHoursAndMinutes(data.duration);

        //this.support = (this.support != "") ? AwsService.generate_link(this.support) : this.support;
        let nbr_modules = 0;
        let nbr_chapter = 0;
        let modules: Array<Module> = [];
        let reviews: Array<Review> = [];
        let categories: Array<Category> = [];

        data.categories?.forEach((categoryItem: any) => {
          categories.push(new Category(categoryItem));
        });

        this.categories = categories;

        this.very_short_description = (this.short_description.length > 30) ? this.short_description.substring(0, 30) + "..." : this.short_description;

        data.modules?.forEach((moduleItem: any) => {
          let module = new Module(moduleItem);
          let chapters: Array<Chapter> = [];
          moduleItem.chapters.forEach((chapterItem: any) => {
            let chapter = new Chapter(chapterItem);
            chapters.push(chapter);
            nbr_chapter++;
          });
          module.chapters = chapters;
          modules.push(module);
          nbr_modules++;
        });
        this.modules = modules;
        this.nbr_modules = nbr_modules;
        this.nbr_chapter = nbr_chapter;
        data.reviews?.forEach((reviewItem: any) => {

          if (reviewItem.student != null) {
            
            reviewItem["full_name"] = reviewItem.student.first_name + " " + reviewItem.student.last_name;
            reviewItem["thumbnail"] = (reviewItem.student.avatar) ? AwsService.generate_link((reviewItem.student.avatar.formats.thumbnail != null) ? reviewItem.student.avatar.formats.thumbnail.url : reviewItem.student.avatar.url)  : environment.default_avatar;
          } else {
            reviewItem["full_name"] = reviewItem.full_name;
            reviewItem["thumbnail"] = (reviewItem.photo) ? AwsService.generate_link(((reviewItem.photo.formats.thumbnail != null && reviewItem.photo.formats != null)) ? reviewItem.photo.formats.thumbnail.url : reviewItem.photo.url)  : "";
          }

          reviews.push(new Review(reviewItem));
        });
        this.reviews = reviews;
        this.profile = new Profile(data.profile as Partial<Profile>);
        if (this.thumbnail == "") {
          if (data.couverture) {
            this.thumbnail = AwsService.generate_link((data.couverture.formats.medium) ? ((data.couverture.formats.medium != null) ? data.couverture.formats.medium.url : data.couverture.url)  : data.couverture.url);
          } else {
            this.thumbnail = environment.default_cover;
          }
        }
      }
    }

  }

  static attributeToJson(item: any) {

    if (item.attributes.profile.data != null) {
      let obj = item.attributes;
      obj["id"] = item.id;
      obj["is_draft"] = (obj["is_draft"] == null || obj["is_draft"] == undefined) ? false : obj["is_draft"];
      let objProfile = item.attributes.profile.data.attributes;
      objProfile["id"] = item.attributes.profile.data.id;
      objProfile["avatar"] = (item.attributes.profile.data.attributes.avatar.data != null) ? item.attributes.profile.data.attributes.avatar.data.attributes : null;
      obj["profile"] = objProfile;
      obj["couverture"] = (item.attributes.couverture.data == null) ? null : item.attributes.couverture.data.attributes;
      obj["thumbnail"] = (obj["couverture"] == null) ? environment.default_cover : ((obj["couverture"].formats["medium"] != null && obj["couverture"].formats != null) ? AwsService.generate_link(obj["couverture"].formats["medium"].url) : AwsService.generate_link(obj["couverture"].url) );
      
      let arrayModules: Array<any> = [];

      if (item.attributes.modules) {
        item.attributes.modules.data.forEach((itemMod: any) => {
          let objItemMod = itemMod.attributes;
          objItemMod["id"] = itemMod.id;
          let arrayChapts: Array<any> = [];
          objItemMod.chapters.data.forEach((itemChapt: any) => {
            let objItemChapt = itemChapt.attributes;
            objItemChapt["id"] = itemChapt.id;
            arrayChapts.push(objItemChapt);
          });
          objItemMod["chapters"] = arrayChapts;
          arrayModules.push(objItemMod);
        });
      }


      obj["modules"] = arrayModules;

      let arrayReviews: Array<any> = [];
      item.attributes.reviews.data.forEach((itemReview: any) => {

        let student = null;
        if (itemReview.attributes.student) {
          if (itemReview.attributes.student.data != null) {
            student = itemReview.attributes.student.data.attributes;
            student["id"] = itemReview.attributes.student.data.id;
            student["avatar"] = (itemReview.attributes.student.data.attributes.avatar.data != null) ? itemReview.attributes.student.data.attributes.avatar.data.attributes : null;
          }
        }

        let objItemReview = itemReview.attributes;
        objItemReview["id"] = itemReview.id;
        objItemReview["student"] = student;

        arrayReviews.push(objItemReview);

      });


      obj["reviews"] = arrayReviews;
      let arrayCategories: Array<any> = [];
      if (item.attributes.categories != undefined) {

        item.attributes.categories.data.forEach((itemCategory: any) => {
          let objItemCat = itemCategory.attributes;
          objItemCat["id"] = itemCategory.id;
          arrayCategories.push(objItemCat);

        });
      }
      obj["categories"] = arrayCategories;


      return obj;
    } else {
      return null;
    }


  }

  short_title() {
    return (this.title.length > 30) ? this.title.substring(0, 30) + "..." : this.title;
  }

}
