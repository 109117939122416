import { Chapter } from "./chapter-model";

export class Module {
    id?: number;
    title?: string;
    chapters: Array<Chapter> = [];
    duration: string = "";

    constructor(data: Partial<Module>) {
        Object.assign(this, data);
        
        this.chapters.forEach((item:any)=> {
        });

        
    }


}
