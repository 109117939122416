<div class="banner-area bg-3">
    <div class="container">
        <div class="banner-content style-three">
            <a routerLink="/index-3">
                <img src="assets/images/logo.png" alt="Image">
            </a>
            <span class="top-title">FOR A BETTER FUTURE</span>
            <h1>Make Your Dream Come True With Our Course</h1>
            <div class="search-bar">
                <form>
                    <input class="form-control" type="search" placeholder="What do you want to learn?" aria-label="Search">
                    <button class="btn search-btn" type="submit">
                        <i class="ri-search-line"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
    <div class="shape banner-shape-img-1"><img src="assets/images/banner/banner-shape-img-1.png" alt="Image"></div>
    <div class="shape banner-shape-img-2"><img src="assets/images/banner/banner-shape-img-2.png" alt="Image"></div>
</div>