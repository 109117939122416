import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCardHorizontalComponent } from './course-card-horizontal.component';
import { RouterModule } from '@angular/router';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@NgModule({
  declarations: [
    CourseCardHorizontalComponent
  ],
  exports: [
    CourseCardHorizontalComponent
  ],
  imports: [
    CommonModule,
    NgbRatingModule,
    RouterModule,
    LazyLoadImageModule
  ]
})
export class CourseCardHorizontalModule { }
