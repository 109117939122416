<!-- Pricing -->
<div class="middle my-5">
    <label (click)="setType('Particulier')">
        <input type="radio" name="radio" checked />
        <div class="box">
            <span style="bottom: 100px !important;">Particulier</span>
        </div>
    </label>

    <label (click)="setType('Professionnel')">
        <input type="radio" name="radio" />
        <div class="box">
            <span style="bottom: 100px !important;">Professionnel</span>
        </div>
    </label>
</div>
<div class="pricing-area pb-70">
    <div class="container" *ngIf="abonnement_type == 'Particulier'">
        <div class="section-title">
            <span class="top-title">FORFAIT TARIFAIRE</span>
            <h2>Plan tarifaire transparent pour vous !</h2>
        </div>
        <div class="row justify-content-md-center" *ngIf="!loading">


            <!--
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-title py-4" style="height: 124px;">
                        <h3>Abonnement test ( 1 Jour )</h3>
                        <p class="">Accès illimité à vie à toutes vos formations préférées.</p>
                    </div>
                    <hr>
                    <ul>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès à tous les vidéos de formation
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès 24/24 et 7/7 pendant toute la période de souscription
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Le catalogue est mis à jour avec de nouvelle vidéo
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès Mac, PC, tablettes et smartphone
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Attestation à la fin de chaque formation
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Téléchargement des ressources de la formation
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès aux supports de la formation
                        </li>
                    </ul>
                    <button type="button" class="SS_ProductCheckout" (click)="paySubscriptionTest()"
                        *ngIf="!access_to_course">Démarrer test</button>
                </div>
            </div>
-->


            <div class="col-lg-4 col-md-6" *ngFor="let pricing of selected_pricing_list">
                <div class="single-pricing">
                    <div class="pricing-title">
                        <h3>{{ pricing.name }}</h3>
                        <p>Plan tarifaire de base pour l'apprentissage</p>
                        <div class="d-flex" *ngIf="!active_gift || !pricing.promo_active">
                            <h2 class="text-primary">{{(pricing.promo_active) ? pricing.price_promo : pricing.price }}
                                €</h2>
                            <h4 *ngIf="pricing.promo_active" class="text-danger ps-3"><span
                                    class="text-decoration-line-through">{{ pricing.price }} €</span></h4>
                        </div>
                        <div class="d-flex" *ngIf="active_gift && pricing.promo_active && gift_sliders.length > 0">
                            <h2 class="text-primary">{{ pricing.price }} €</h2>
                            <span class="fa fa-plus mx-2"></span>
                            <span class="fa fa-gift fa-2x"></span>
                        </div>
                    </div>
                    <hr>
                    <ul>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès à tous les vidéos de formation
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès 24/24 et 7/7 pendant toute la période de souscription
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Le catalogue est mis à jour avec de nouvelle vidéo
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Accès Mac, PC, tablettes et smartphone
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Attestation à la fin de chaque formation
                        </li>
                        <li>
                            <i class="ri-check-line" *ngIf="pricing.duration == 12"></i>
                            <i class="ri-close-line" *ngIf="pricing.duration != 12"></i>
                            Accès aux QCM de la formation
                        </li>
                        <li *ngFor="let feature of pricing.more_features">
                            <i class="ri-check-line"></i>
                            {{ feature }}
                        </li>
                        <li *ngFor="let feature of pricing.features_blocked">
                            <i class="ri-close-line"></i>
                            {{ feature }}
                        </li>
                        <li>
                            <i class="ri-check-line" *ngIf="pricing.duration == 12"></i>
                            <i class="ri-close-line" *ngIf="pricing.duration != 12"></i>
                            Téléchargement des ressources de la formation
                        </li>
                        <li>
                            <i class="ri-check-line" *ngIf="pricing.duration == 12"></i>
                            <i class="ri-close-line" *ngIf="pricing.duration != 12"></i>
                            Accès aux supports de la formation
                        </li>
                    </ul>
                    <div class="w-100 mt-5"
                        *ngIf="pricing.promo_active && gift_sliders.length > 0 && !pricing_selected">
                        <div class="form-group" style="display: flex;
                        justify-content: space-between;">
                            <h5>Réduction</h5>
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="active_gift">
                                <span class="slider round"></span>
                            </label>
                            <h5>Cadeau</h5>
                        </div>
                        <p style="font-size:14px;margin-top:20px;"><strong>NB :</strong> Si vous basculez vers la
                            gauche, une réduction est appliquée, et si vous basculez vers la droite, vous payez sans
                            réduction et vous recevrez un cadeau à votre adresse.</p>
                    </div>
                    <div class="pricing-btn w-100">
                        <button type="button" class="SS_ProductCheckout" (click)="paySubscription(pricing)"
                            *ngIf="!access_to_course">Démarrer</button>
                        <button class="btn btn-primary w-100 mt-5" (click)="mes_abonnements()"
                            *ngIf="pricing_selected && pricing.id == pricing_selected.id">Mes abonnements</button>
                    </div>

                </div>
            </div>
        </div>
        <app-shimmer type="pricing" *ngIf="loading"></app-shimmer>
        
    </div>
    <div class="container" *ngIf="abonnement_type == 'Professionnel'">
        <div class="section-title">
            <span class="top-title">FORFAIT TARIFAIRE</span>
            <h2>Demandez votre devis</h2>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-8">
                <div class="contact-form main-contact-form">
                    <form id="contactForm" [formGroup]="FormContact" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Nom de l'entreprise (*) :</label>
                                    <input [ngClass]="{'border-danger':contact.name.errors && submitFormContact}"
                                        type="text" formControlName="name" id="name" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Adresse email (*) :</label>
                                    <input [ngClass]="{'border-danger':contact.email.errors && submitFormContact}"
                                        type="email" formControlName="email" id="email" class="form-control" EmailValidator>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Numéro téléphone (*) :</label>
                                    <input [ngClass]="{'border-danger':contact.phone.errors && submitFormContact}"
                                        type="text" formControlName="phone" id="phone" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Nombre de licences (*) :</label>
                                    <select formControlName="nbr_licence" id="nbr-licence" class="form-control"
                                        [ngClass]="{'border-danger':contact.nbr_licence.errors && submitFormContact}">
                                        <option value="1 - 10 licences">1 - 10 licences</option>
                                        <option value="10 - 30 licences">10 - 30 licences</option>
                                        <option value="30 - 50 licences">30 - 50 licences</option>
                                        <option value="plus de 50 licences">plus de 50 licences</option>
                                    </select>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Sujet (*) :</label>
                                    <input [ngClass]="{'border-danger':contact.subject.errors && submitFormContact}"
                                        type="text" formControlName="subject" id="subject" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Message (*) :</label>
                                    <textarea [ngClass]="{'border-danger':contact.message.errors && submitFormContact}"
                                        formControlName="message" class="form-control" id="message" cols="30"
                                        rows="6"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p>(*) : Ce champ est obligatoire</p>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Envoyer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>