import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  entity: string = "/certificates";

  constructor(private http: HttpClient, private authService: AuthService) { }

  create(values: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/add", values, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  get(token: string): Observable<any> {
    return this.http.get(environment.api + this.entity + "/find/" + token, {
      headers: new HttpHeaders({ 'Content-Type': 'application/text' })
    }
    );
  }
}
