import { Component, NgZone, Inject, PLATFORM_ID, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { User } from './models/user-model';
import { CategoryService } from './services/cateogry/category.service';
import { Category } from './models/category-model';
import { SeoService } from './seo/seo.service';
import { PricingService } from './services/pricing/pricing.service';
import { Pricing } from './models/pricing-model';
import { SettingService } from './services/setting/setting.service';
import { Setting } from './models/setting-model';

import { isPlatformBrowser } from '@angular/common';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {

    show_header_footer: boolean = false;

    loading_user: boolean = true;
    loading_categories: boolean = true;
    loading_settings: boolean = true;
    title = 'le-nid-it';
    user: any = null;
    location: any;
    routerSubscription: any;
    someInterval: any;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private ngZone: NgZone, private settingService: SettingService, private seoService: SeoService, private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService, private userService: UserService, private categoryService: CategoryService, private pricingService: PricingService) {

    }

    ngOnInit() {
        // update meta tags 
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe((event) => {
            if (Object.keys(event).length > 0) {
                this.seoService.generateTags(event);
            }
        });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(event => this.modifyHeader(event));

        this.check_datas();
        //this.get_categories_light();
        //this.get_pricings();
        //this.get_settings();
        if (isPlatformBrowser(this.platformId)) {
            this.recallJsFuntions();
        }

        this.ngZone.runOutsideAngular(() => {
            this.someInterval = setInterval(() => {
                this.check_datas();
            }, 10000)
        });

    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    get_categories_light() {
        this.categoryService.get_light().subscribe(res => {
            let categories: Array<any> = [];
            res.data.forEach((item: any) => {
                categories.push(Category.attributeToJson(item));
            });
            localStorage.setItem('categories', JSON.stringify(categories));
        }, err => {

        }).add(() => {
            this.loading_categories = false;
        });
    }

    get_pricings() {

        this.pricingService.get().subscribe((res: any) => {
            let pricings: Array<any> = [];
            res.data.forEach((item: any) => {
                pricings.push(Pricing.attributeToJson(item));
            });
            localStorage.setItem('pricings', JSON.stringify(pricings));
        });

    }

    get_settings() {
        if (!localStorage.getItem('show_settings')) {
            localStorage.setItem('show_settings', "1");
        }
        this.settingService.get().subscribe((res) => {
            localStorage.setItem('settings', JSON.stringify(Setting.attributeToJson(res.data)));
        }).add(() => {
            this.loading_settings = false;
        });
    }

    check_datas() {

        if (this.authService.currentUserValue) {

            if (this.authService.currentUserValue instanceof User) {
                this.loading_user = false;
            } else {
                this.get_user_datas();
            }

        } else {
            this.loading_user = false;
        }
    }

    get_user_datas() {
        this.userService.get(this.authService.currentUserValue.token).subscribe(user => {
            let userJson = User.toJson(user, this.authService.currentUserValue.token);
            if (userJson != null) {
                if (userJson.session_id == this.authService.currentUserValue.session_id) {
                    localStorage.setItem('currentUser', JSON.stringify(userJson));
                    this.authService.callCurrentUserSubject(userJson);
                } else {
                    this.authService.logout();
                }
            } else {
                this.authService.logout();
            }
        }, err => {
            this.authService.logout();
        }).add(() => {
            this.loading_user = false;
        });
    }

    modifyHeader(route: any) {
        if (route.url) {
            if (route.url.includes("/attestation")) {
                this.show_header_footer = false;
            } else if ( route.url.includes("/login") || route.url.includes("/register") || route.url.includes("/coming-soon")) {
                /*
                this.activatedRoute.queryParams.subscribe(params => {
                    if (params['client_id'] === 'mobile') {
                        this.show_header_footer = false;
                    }
                });
                */
                this.show_header_footer = false;
            } else {
                this.show_header_footer = true;
            }

        } else {
            this.show_header_footer = true;
        }
    }

}