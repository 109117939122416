import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthAdminService } from '../services/auth-admin/auth-admin.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authAdminService: AuthAdminService
  ) {}
  

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin();
  }

  private async checkLogin(): Promise<boolean | UrlTree> {
    try {
      let admin_is_connected = await this.authAdminService.isAuthenticated();
      if (admin_is_connected) {
        return true;
      } else {
        this.router.navigate(['/coming-soon']);
        return false;
      }
    } catch (error) {
      this.router.navigate(['/coming-soon']);
      return false;
    }
  }
}
