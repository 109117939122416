import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { UserResolver } from './services/user/user.resolver';
import { AdminAuthGuard } from './_helpers/admin-auth.guard';

const routes: Routes = [
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('./components/pages/coming-soon/coming-soon-page.module').then(
        (m) => m.ComingSoonPageModule
      ),
  },
  {
    path: 'admin/login',
    loadChildren: () =>
      import('./components/pages/admin-login/admin-login-page.module').then(
        (m) => m.AdminLoginPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./components/pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'attestation/:token',
    loadChildren: () =>
      import('./components/pages/attestation/attestation.module').then(
        (m) => m.AttestationModule
      ),
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./components/pages/courses-page/courses-page.module').then(
        (m) => m.CoursesPageModule
      ),
  },
  {
    path: 'upcoming-courses',
    loadChildren: () =>
      import(
        './components/pages/upcoming-courses-page/upcoming-courses-page.module'
      ).then((m) => m.UpcomingCoursesPageModule),
  },
  {
    path: 'courses-details/:slug',
    loadChildren: () =>
      import(
        './components/pages/courses-details-page/courses-details-page.module'
      ).then((m) => m.CoursesDetailsPageModule),
  },
  {
    path: 'category/:slug',
    loadChildren: () =>
      import(
        './components/pages/courses-category-page/courses-category-page.module'
      ).then((m) => m.CoursesCategoryPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/pages/login-page/login-page.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./components/pages/register-page/register-page.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./components/pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import(
        './components/pages/confirmation-email/confirmation-email.module'
      ).then((m) => m.ConfirmationEmailModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./components/pages/faq-page/faq-page.module').then(
        (m) => m.FaqPageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import(
        './components/pages/privacy-policy-page/privacy-policy-page.module'
      ).then((m) => m.PrivacyPolicyPageModule),
  },
  {
    path: 'mentions-legales',
    loadChildren: () =>
      import(
        './components/pages/mentions-legales/mentions-legales.module'
      ).then((m) => m.MentionsLegalesModule),
  },
  {
    path: 'tarification',
    loadChildren: () =>
      import('./components/pages/pricing-page/pricing-page.module').then(
        (m) => m.PricingPageModule
      ),
  },
  {
    path: 'abonnements/success',
    loadChildren: () =>
      import(
        './components/pages/pricings-success/pricings-success.module'
      ).then((m) => m.PricingsSuccessModule),
  },
  {
    path: 'abonnements/mettre-a-niveau/:subscription',
    loadChildren: () =>
      import(
        './components/pages/switch-subscription/switch-subscription.module'
      ).then((m) => m.SwitchSubscriptionModule),
  },
  {
    path: 'abonnements/cancel',
    loadChildren: () =>
      import('./components/pages/pricings-cancel/pricings-cancel.module').then(
        (m) => m.PricingsCancelModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./components/pages/contact-page/contact-page.module').then(
        (m) => m.ContactPageModule
      ),
  },
  {
    path: 'recherche',
    loadChildren: () =>
      import('./components/pages/search-page/search-page.module').then(
        (m) => m.SearchPageModule
      ),
  },
  {
    path: 'mon-apprentissage',
    loadChildren: () =>
      import(
        './components/user-dashboard/mon-apprentissage/mon-apprentissage.module'
      ).then((m) => m.MonApprentissageModule),
  },
  {
    path: 'mes-abonnements',
    loadChildren: () =>
      import(
        './components/user-dashboard/mes-abonnements/mes-abonnements.module'
      ).then((m) => m.MesAbonnementsModule),
  },
  {
    path: 'mes-factures',
    loadChildren: () =>
      import(
        './components/user-dashboard/mes-factures/mes-factures.module'
      ).then((m) => m.MesFacturesModule),
  },
  {
    path: 'mes-moyens-paiement',
    loadChildren: () =>
      import(
        './components/user-dashboard/mes-moyens-paiement/mes-moyens-paiement.module'
      ).then((m) => m.MesMoyensPaiementModule),
  },
  {
    path: 'mon-profil',
    loadChildren: () =>
      import('./components/user-dashboard/mon-profil/mon-profil.module').then(
        (m) => m.MonProfilModule
      ),
  },
  {
    path: 'mes-achats',
    loadChildren: () =>
      import('./components/user-dashboard/mes-achats/mes-achats.module').then(
        (m) => m.MesAchatsModule
      ),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./components/pages/cart-page/cart-page.module').then(
        (m) => m.CartPageModule
      ),
  },
  {
    path: 'checkout/billing-profile',
    loadChildren: () =>
      import('./components/pages/billing-infos/billing-infos.module').then(
        (m) => m.BillingInfosModule
      ),
  },
  {
    path: 'checkout/payment',
    loadChildren: () =>
      import(
        './components/pages/checkout-payment/checkout-payment.module'
      ).then((m) => m.CheckoutPaymentModule),
  },

  /*

    { path: 'event-details', component: EventsDetailsPageComponent },
    { path: 'about', component: AboutPageComponent },
    { path: 'instructors', component: InstructorsPageComponent },
    { path: 'instructor-profile', component: InstructorsProfilePageComponent },
    { path: 'become-an-instructor', component: BecomeAnInstrutorPageComponent },
    { path: 'events', component: EventsPageComponent },
    { path: 'zoom-meetings', component: ZoomMeetingsPageComponent },
    // User dashboard
    { path: 'user-dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },
    // Admin dashboard
    { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard] },
    { path: 'admin-profile', component: AdminProfileComponent, canActivate: [AuthGuard] },
    { path: 'admin-courses', component: AdminCoursesComponent, canActivate: [AuthGuard] },
    { path: 'admin-purchase-history', component: AdminPurchaseHistoryComponent, canActivate: [AuthGuard] },
    { path: 'admin-settings', component: AdminSettingsComponent, canActivate: [AuthGuard] },
    { path: 'user-reviews', component: UserReviewsComponent, canActivate: [AuthGuard] },
    // Instructor dashboard
    { path: 'instructor-dashboard', component: InstructorDashboardComponent },
    { path: 'instructor-dashboard-profile', component: InstructorProfileComponent },
    { path: 'instructor-courses', component: InstructorCoursesComponent },
    { path: 'add-courses', component: InstructorAddCoursesComponent },
    { path: 'instructor-purchase-history', component: InstructorPurchaseHistoryComponent },
    { path: 'instructor-earnings', component: InstructorEarningsComponent },
    { path: 'instructor-withdraw', component: InstructorWithdrawComponent },
    { path: 'instructor-students', component: InstructorStudentsComponent },
    { path: 'instructor-settings', component: InstructorSettingsComponent },
    */

  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [AdminAuthGuard],
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [UserResolver],
})
export class AppRoutingModule {}
