<!-- Default Achievements for All Pages -->
<div 
    class="counter-area pt-70 pb-100"
    [ngClass]="{'d-none' : router.url === '/index-2'}"
>
    <div class="container">
        <div class="achievements">
            <div class="achievements-contents">
                <h3>Nos réalisations</h3>
                <p>Apprenez les secrets du succès de la vie, tous <br>les succès que nous avons obtenus dans la réalisation des objectifs.</p>
                <img src="assets/images/achievements-img.png" alt="Image">
            </div>
            <div class="counter-bg p-70">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-6">
                        <div class="single-counter-item">
                            <h2>
                                <span class="odometer" [countUp]="25000">00</span>
                                <span class="target">+</span>
                            </h2>
                            <h4>Coures & Videos</h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-6">
                        <div class="single-counter-item">
                            <h2>
                                <span class="odometer" [countUp]="125000">00</span>
                                <span class="target">+</span>
                            </h2>
                            <h4>Étudiants inscrits</h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-6">
                        <div class="single-counter-item">
                            <h2>
                                <span class="odometer" [countUp]="10000">00</span>
                                <span class="target">+</span>
                            </h2>
                            <h4>Formateurs</h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-6">
                        <div class="single-counter-item">
                            <h2>
                                <span class="odometer" [countUp]="100">00</span>
                                <span class="target">%</span>
                            </h2>
                            <h4>Taux de satisfaction</h4>
                        </div>
                    </div>
                </div>
                <div class="counter-shape">
                    <img src="assets/images/counter-shape.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Achievements for Only Home Demo 2 -->
<div 
    class="counter-area bg-color-f2f8fa pb-70 d-none"
    [ngClass]="{'d-block' : router.url === '/index-2'}"
>
    <div class="container">
        <div class="section-title">
            <h2>Nos réalisations</h2>
            <p>Apprenez les secrets du succès de la vie, tous les succès que nous <br>avons obtenus dans la réalisation des objectifs.</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-6">
                <div class="single-counter-item">
                    <img src="assets/images/icon/online-course.png" alt="Image">
                    <h2>
                        <span class="odometer" [countUp]="25000">00</span>
                        <span class="target">+</span>
                    </h2>
                    <h4>Coures & Videos</h4>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6">
                <div class="single-counter-item">
                    <img src="assets/images/icon/contract.png" alt="Image">
                    <h2>
                        <span class="odometer" [countUp]="125000">00</span>
                        <span class="target">+</span>
                    </h2>
                    <h4>Étudiants inscrits</h4>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6">
                <div class="single-counter-item">
                    <img src="assets/images/icon/instructor.png" alt="Image">
                    <h2>
                        <span class="odometer" [countUp]="10000">00</span>
                        <span class="target">+</span>
                    </h2>
                    <h4>Formateurs</h4>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6">
                <div class="single-counter-item">
                    <img src="assets/images/icon/satisfaction.png" alt="Image">
                    <h2>
                        <span class="odometer" [countUp]="100">00</span>
                        <span class="target">%</span>
                    </h2>
                    <h4>Taux de satisfaction</h4>
                </div>
            </div>
        </div>
    </div>
</div>