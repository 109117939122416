import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  entity: string = "/stripes";

  constructor(private http: HttpClient, private authService: AuthService) { }

  create(data: any, jwt: string): Observable<any> {
    return this.http.post(environment.api + this.entity + "?populate=*", {
      "data": data
    }, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } }
    );
  }

  createSubscription(data: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/create-subscription", data, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  manageBilling(): Observable<any> {
    return this.http.post(environment.api + this.entity + "/manage-billing", { "customer": this.authService.currentUserValue.stripe.customer_id }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  mes_paiements(): Observable<any> {
    return this.http.post(environment.api + this.entity + "/payment-method", { "customer": this.authService.currentUserValue.stripe.customer_id }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  mes_factures(body: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/invoices", body, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  switch(subscription_token: any, paymentMethodId: string): Observable<any> {
    return this.http.post(environment.api + this.entity + "/switch-subscription", { "subscription_token": subscription_token, "paymentMethodId": paymentMethodId }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  remove_payment_card(payment_method_token: string): Observable<any> {
    return this.http.post(environment.api + this.entity + "/remove-payment-method", { "payment_method_token": payment_method_token }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  cancel_subscription_at_period_end(values: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/cancel-period-end", values, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  get_subscription_by_token(subscription_token: string) {
    return this.http.get(environment.api + this.entity + "/find-subscription-by-token/" + subscription_token, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  create_payment_intent_card() {
    return this.http.get(environment.api + this.entity + "/create-setup-intent-card", { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  attach_card(tokenPaymentMethod: string): Observable<any> {
    return this.http.post(environment.api + this.entity + "/attach-card", { "token_card": tokenPaymentMethod }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  purchase_courses(slug_courses: any, billing_info: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/purchase-courses", { "courses": slug_courses, "billing_info": billing_info }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

}
