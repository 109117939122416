export const environment = {
  production: false,
  api: "https://api.lenidit.com/api",
  api_base_link: "https://api.lenidit.com",
  url: 'https://dev.lenidit.com/',
  stripe_pk: "pk_test_51LD5BcJXa9nkalFd3lCaUjjYkwbdjMmq3c0YEdrl44l3H5MEef7ouz1jBJsIRwvxIE2gfzLyyfUl0PKTrSX7g6Aa00995Taez3",
  default_avatar: "assets/images/avatar.png",
  default_cover: "assets/images/courses/course-1.jpg",
  googleProvider: "339380023802-8p5rd8jcmd7l2in4vn60b981mqu1og6t.apps.googleusercontent.com",
  facebookProvider: "553587223484595",
  admin_email: "app.lenidit@gmail.com",
  apple_client_id: "com.lenidit.signin",
  price_stripe_for_test: "price_1OVHoiJXa9nkalFdv7Uq6mp6",
  defaultImage: "assets/images/default-image.webp"
};
