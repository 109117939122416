import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss']
})
export class ShimmerComponent implements OnInit {

  @Input() size: number = 4;
  @Input() type: string = "";

  constructor() { }

  ngOnInit(): void {

  }

  arrayOne(n: number): any[] {
    return Array(n ? n : 0);
  }

}
