import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '-';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {

			const date = value.split(this.DELIMITER);
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10),
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		let day = (date) ? (date!.day.toString().length == 1) ? "0" + date!.day : date!.day : null;
		let month = (date) ? (date!.month.toString().length == 1) ? "0" + date!.month : date!.month : null;
		return date ? date.year + this.DELIMITER + month + this.DELIMITER + day : null;
	}
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		let day = (date) ? (date!.day.toString().length == 1) ? "0" + date!.day : date!.day : '';
		let month = (date) ? (date!.month.toString().length == 1) ? "0" + date!.month : date!.month : '';
		return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
	}
}