import { Formation } from "./formation-model";
import { Profile } from "./profile-model";
import { environment } from "src/environments/environment";
import { AwsService } from "../services/aws/aws.service";

export class Review {
    id?: number;
    student: Profile = new Profile({});
    formation: Formation = new Formation({});
    note: number = 0;
    comment?: string;
    full_name: string = "";
    show_in_home: boolean = false;
    photo!: any;
    thumbnail: string = "";
    status: string = "";
    createdAt: string = "";

    constructor(data: Partial<Review>) {
        Object.assign(this, data);
        if (data.id !== undefined) {
            if (data.student != null) {
                this.student = new Profile(data.student!);
                this.thumbnail = this.student.thumbnail;
            }
        }
    }

    static attributeToJson(item: any) {
        
        let obj = item.attributes;
        obj["id"] = item.id;
        if (item.attributes.photo) {
            obj["photo"] = (item.attributes.photo.data == null) ? null : item.attributes.photo.data.attributes;
        } else {
            obj["photo"] = null;
        }

        //obj["thumbnail"] = (obj["photo"] == null) ? environment.default_avatar : environment.api_base_link + obj["photo"].url;

        if (item.attributes.student) {
            if (item.attributes.student.data != null) {
                let objStudent = item.attributes.student.data.attributes;
                objStudent["id"] = item.attributes.student.data.id;
                obj["student"] = objStudent;
                if (obj["full_name"] == "" || obj["full_name"] == null || obj["full_name"] == "null") {
                    obj["full_name"] = obj["student"]["first_name"] + " " + obj["student"]["last_name"];
                }
                obj["student"]["avatar"]= (objStudent.avatar) ? ((objStudent.avatar.data != null) ? objStudent.avatar.data.attributes : null)  : null;
                obj["thumbnail"] = (objStudent.avatar) ? ((objStudent.avatar.data != null) ? objStudent.avatar.data.attributes.formats.thumbnail.url : environment.default_avatar ) : environment.default_avatar;
            } else {
                obj["student"] = null;
                obj["full_name"] = item.attributes.full_name;
                obj["thumbnail"] = AwsService.generate_link(item.attributes.photo.formats.thumbnail.url);
            }
        } else {
            obj["student"] = null;
            obj["full_name"] = item.attributes.full_name;
            obj["thumbnail"] = (obj["photo"] == null) ? environment.default_avatar : AwsService.generate_link(item.attributes.photo.formats.thumbnail.url);
        }

        if (item.attributes.formation) {
            let objFormation = item.attributes.formation.data.attributes;
            objFormation["id"] = item.attributes.formation.data.id;
            objFormation['profile'] = null;
            obj["formation"] = objFormation;
        } else {
            obj["formation"] = null;
        }

        return obj;
    }

}
