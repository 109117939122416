import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  constructor() { }

  static generate_link(url: string){
    //let my_url = (url.includes(environment.aws_url)) ? url : environment.api_base_link+url;
    return environment.api_base_link+url;
  }
}
