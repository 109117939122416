<div class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <h1>4 <span class="red">0</span> 4</h1>
            <h3>Oops! Page non trouvée</h3>
            <p>La page que vous cherchez n'existe pas</p>
            <a routerLink="/" class="default-btn two">Retour à la page d'accueil</a>
        </div>
    </div>
</div>
