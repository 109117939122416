import { OptionQuestion } from "./option-question-model";

export class Question {
    id?: number;
    text: string = "";
    options: Array<string> = [];
    correctOptionIndices: Array<number> = [];
    multi_select: boolean = false;
    optionsFormQuestion: Array<OptionQuestion> = [];
    selectedOptionIndices: Array<number> = [];
    order?: number;

    constructor(data?: Partial<Question>) {
        if (data) {
            Object.assign(this, data);
            if(data.options){
                let optionsFormQuestion: Array<OptionQuestion> = [];
                data.options.forEach((item: any,index)=> {
                    optionsFormQuestion.push(new OptionQuestion({ "index": index+1 , "value": item}));
                });
                this.optionsFormQuestion = optionsFormQuestion;
            }
        }
    }

    static toObject(item: any) {
        let obj = item;
        let options: Array<string> = [];
        Object.entries(obj.options).forEach(([key, value], index) => {
            options.push(value as string);
        });
        obj.options = options;
        
        let correctOptionIndices: Array<number> = [];
        obj.correctOptionIndices.split(",").forEach((item: any)=> {
            correctOptionIndices.push(Number.parseInt(item));
        });
        obj.correctOptionIndices = correctOptionIndices;
        
        obj["multi_select"] = correctOptionIndices.length > 1;
        
        return obj;
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;

        let options: Array<string> = [];
        Object.entries(obj.options).forEach(([key, value], index) => {
            options.push(value as string);
        });
        obj.options = options;
        return obj;

    }

}
