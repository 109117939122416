<div class="d-lg-flex">
    <img class="" width="200" [defaultImage]="defaultImage" [lazyLoad]="formation.thumbnail" [useSrcset]="true"
        alt="image">
    <div class="">
        <strong class="text-gray-dark">{{ formation.title }}</strong>
        <h2 *ngIf="formation.price > 0 && !formation.has_promo">{{ formation.price }}€</h2>
        <div class="d-flex">
            <span *ngIf="formation.has_promo" class="text-decoration-line-through"
                style="font-size: 18px !important;font-weight: bold;color:rgb(190, 30, 30);">{{ formation.price
                }}€</span>
            <h2 *ngIf="formation.new_price > 0 && formation.has_promo">{{ formation.new_price }}€</h2>
            <h2 *ngIf="(formation.price == 0 && !formation.has_promo) || (formation.new_price == 0 && formation.has_promo)">
                Gratuit</h2>
        </div>


        <ul class="my-2 p-0 list" *ngIf="(formation.certificate != '' || formation.pourcentage == 100) ? false : true">
            <li>
                <div class="d-flex pb-2">
                    <i class="ri-group-fill pe-2 text-info"></i>
                    Accès illimité
                </div>
            </li>
            <li *ngIf="formation.certificate == '' && formation.pourcentage != 100">
                <div class="d-flex pb-2 text-black-100">
                    <i class="ri-file-text-line pe-2 text-info"></i>
                    Attestation de formation
                </div>
            </li>
            <li *ngIf="formation.support == '' || formation.support == null">
                <div class="d-flex pb-2 text-black-50">
                    <i class="ri-file-download-fill pe-2 text-info"></i>
                    Ressources de formation
                </div>
            </li>
        </ul>
    </div>
</div>