import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category } from 'src/app/models/category-model';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  entity: string = "/categories";
  public categoriesSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('categories')!));

  constructor(private http: HttpClient, private authService: AuthService) { }

  public get categories(): any {

    let array: Array<Category> = [];

    if (this.categoriesSubject.value != null) {
      this.categoriesSubject.value.forEach((item: any) => {
        let category = new Category(item);
        array.push(category);
      });
    }

    return array;
  }

  get_tasks(): Observable<any> {
    return this.http.get(environment.api + "/tasks", httpOptions);
  }

  get_light(): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity + "?populate=image_category", header_options);
  }

  get(): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity + "?populate=image_category,formations.reviews.student.avatar,formations.profile.avatar,formations.couverture,formations.modules.chapters", header_options);
  }

  get_categories_light() {
    let categories: Array<Category> = [];
    this.get_light().subscribe(res => {
      categories = [];
      res.data.forEach((item: any) => {
        this.categories.push(new Category(Category.attributeToJson(item)));
      });
    });
    return categories;
  }

}
