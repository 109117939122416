import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user-model';
import { ChildActivationStart, Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  entity: string = '/auth';

  public currentUserSubject = new BehaviorSubject<User>(
    JSON.parse(localStorage.getItem('currentUser')!)
  );

  currentUser = this.currentUserSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  callCurrentUserSubject(user: any) {
    this.currentUserSubject.next(user);
  }

  validate_profile(): boolean {
    return (
      this.currentUserValue.profile.first_name != '' &&
      this.currentUserValue.profile.first_name != null &&
      this.currentUserValue.profile.last_name != '' &&
      this.currentUserValue.profile.last_name != null &&
      this.currentUserValue.profile.phone != '' &&
      this.currentUserValue.profile.phone != null &&
      this.currentUserValue.profile.address != '' &&
      this.currentUserValue.profile.address != null &&
      this.currentUserValue.profile.city != '' &&
      this.currentUserValue.profile.city != null &&
      this.currentUserValue.profile.country != '' &&
      this.currentUserValue.profile.country != null &&
      this.currentUserValue.profile.postal_code != '' &&
      this.currentUserValue.profile.postal_code != null
    );
  }

  login(identifier: string, password: string): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/local/',
      {
        identifier,
        password,
      },
      httpOptions
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(new User({}));
    this.currentUser.subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );

    this.router.navigate(['/login']);
  }

  register(user: User): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/local/register',
      JSON.stringify(user),
      httpOptions
    );
  }

  resetPassword(
    code: string,
    password: string,
    passwordConfirmation: string
  ): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/reset-password',
      {
        code,
        password,
        passwordConfirmation,
      },
      httpOptions
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/forgot-password',
      {
        email,
      },
      httpOptions
    );
  }

  resendConfirmation(email: string): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/send-email-confirmation',
      {
        email,
      },
      httpOptions
    );
  }

  loginWithSocial(provider: string, token_access: string) {
    return this.http.get(
      environment.api +
        '/auth/' +
        provider +
        '/callback/?populate=*&access_token=' +
        token_access
    );
  }

  public getUser(): boolean {
    return this.currentUserSubject.value !== null;
  }

  public isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userConnected = this.getUser();
      if (userConnected) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  }
}
