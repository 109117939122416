import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { StickyNavModule } from 'ng2-sticky-nav';
import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';


import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { UpcomingEventsComponent } from './components/common/upcoming-events/upcoming-events.component';
import { AchievementComponent } from './components/common/achievement/achievement.component';
import { GetRegisterComponent } from './components/common/get-register/get-register.component';
import { AboutComponent } from './components/common/about/about.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';

import { HomethreeBannerComponent } from './components/pages/home-demo-three/homethree-banner/homethree-banner.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { WidgetSidebarComponent } from './components/common/widget-sidebar/widget-sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';

import { I18n } from './services/translation/I18n.service';
import { AuthService } from './services/auth/auth.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { JsonPipe } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { environment } from 'src/environments/environment';
import { NgIvsModule } from '@cotufa82/ng-ivs';
import { AdminCoursesComponent } from './components/other/admin-dashboard/admin-courses/admin-courses.component';
import { HomeModule } from './components/pages/home/home.module';
import { AttestationModule } from './components/pages/attestation/attestation.module';
import { CoursesDetailsPageModule } from './components/pages/courses-details-page/courses-details-page.module';
import { CoursesPageModule } from './components/pages/courses-page/courses-page.module';
import { CoursesCategoryPageModule } from './components/pages/courses-category-page/courses-category-page.module';
import { LoginPageModule } from './components/pages/login-page/login-page.module';
import { RegisterPageModule } from './components/pages/register-page/register-page.module';
import { ResetPasswordModule } from './components/pages/reset-password/reset-password.module';
import { ConfirmationEmailModule } from './components/pages/confirmation-email/confirmation-email.module';
import { FaqPageModule } from './components/pages/faq-page/faq-page.module';
import { PrivacyPolicyPageModule } from './components/pages/privacy-policy-page/privacy-policy-page.module';
import { MentionsLegalesModule } from './components/pages/mentions-legales/mentions-legales.module';
import { PricingPageModule } from './components/pages/pricing-page/pricing-page.module';
import { SearchPageModule } from './components/pages/search-page/search-page.module';
import { MonApprentissageModule } from './components/user-dashboard/mon-apprentissage/mon-apprentissage.module';
import { MesAbonnementsModule } from './components/user-dashboard/mes-abonnements/mes-abonnements.module';
import { MesFacturesModule } from './components/user-dashboard/mes-factures/mes-factures.module';
import { MesMoyensPaiementModule } from './components/user-dashboard/mes-moyens-paiement/mes-moyens-paiement.module';
import { MonProfilModule } from './components/user-dashboard/mon-profil/mon-profil.module';
import { ContactPageModule } from './components/pages/contact-page/contact-page.module';
import { PricingsSuccessModule } from './components/pages/pricings-success/pricings-success.module';
import { SwitchSubscriptionModule } from './components/pages/switch-subscription/switch-subscription.module';
import { PricingsCancelModule } from './components/pages/pricings-cancel/pricings-cancel.module';


import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule, NgbTypeaheadModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomAdapter, CustomDateParserFormatter } from 'src/app/services/date-formatter/date-parser.service';
import { CustomDatepickerI18n } from 'src/app/services/translation/I18n.service';

import { ShimmerModule } from './utils/shimmer/shimmer.module';
import { PricingModule } from './components/common/pricing/pricing.module';
import { CourseCardModule } from './components/common/course-card/course-card.module';
import { SharedModule } from './components/pages/shared/shared.module';
import { UpcomingCoursesPageModule } from './components/pages/upcoming-courses-page/upcoming-courses-page.module';
import { CourseCardHorizontalModule } from './components/common/course-card-horizontal/course-card-horizontal.module';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    UpcomingEventsComponent,
    AchievementComponent,
    GetRegisterComponent,
    AboutComponent,
    HometwoBannerComponent,
    HomethreeBannerComponent,
    NotFoundComponent,
    WidgetSidebarComponent,
    AdminCoursesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SocialLoginModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxSimpleCountdownModule,
    NgxScrollTopModule,
    StickyNavModule,
    CountUpModule,
    HttpClientModule,

    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule, 
    BrowserModule,
    GooglePlaceModule,
    JsonPipe,
    ShareButtonsModule,
    NgIvsModule,
    
    ShimmerModule,
    PricingModule,
    CourseCardModule,
    CourseCardHorizontalModule,
    SharedModule
    
  ],
  providers: [I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    AuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleProvider, {
              scope: 'profile email',
              plugin_name: 'lenidit_login'
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookProvider, {
              scope: 'public_profile email',
              return_scopes: true,
              enable_profile_selector: true
            })
          }
        ],
      } as SocialAuthServiceConfig,
    },
    NgbActiveModal
  ], // define custom NgbDatepickerI18n provider

  bootstrap: [AppComponent]
})
export class AppModule { }