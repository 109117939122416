import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from 'src/app/services/cateogry/category.service';

@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.scss']
})
export class AdminCoursesComponent implements OnInit {



  constructor(private categoryService: CategoryService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    
  }


}
