import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user-model';
import { ChildActivationStart, Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthAdminService {
  entity: string = '/auth';

  public currentUserSubject = new BehaviorSubject<User>(
    JSON.parse(localStorage.getItem('leniditAdmin')!)
  );

  currentUser = this.currentUserSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public getUser(): boolean {
    return localStorage.getItem('leniditAdmin') !== null && localStorage.getItem('leniditAdmin') !== undefined;
  }

  callCurrentUserSubject(user: any) {
    this.currentUserSubject.next(user);
  }

  login(identifier: string, password: string): Observable<any> {
    return this.http.post(
      environment.api + this.entity + '/local/',
      {
        identifier,
        password,
      },
      httpOptions
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('leniditAdmin');
    this.currentUserSubject.next(new User({}));
    this.currentUser.subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );

    this.router.navigate(['/admin/login']);
  }

  public isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userConnected = this.getUser();
      if (userConnected) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  }


}
