import { DateFormatterService } from "../services/date-formatter/date-formatter.service";
import { Question } from "./question-model";

export class Chapter {
    id: string = "";
    title: string = "";
    duration: string = "";
    time: number = 0;
    link: string = "";
    locked: boolean = true;
    slug: string = "";
    is_read: boolean = false;
    type: string = "";
    type_video: string = "";
    questions: Array<Question> = [];
    current_question_index: number = 0;
    description: string ="";

    constructor(data?: Partial<Chapter>) {
        if (data) {
            Object.assign(this, data);
            this.time = Number(data.duration);
            this.duration = DateFormatterService.toHoursAndMinutes(data.duration);

            let questions: Array<Question> = [];
            if (data.questions) {
                data.questions?.forEach((questionItem: any) => {
                    questions.push(new Question(Question.toObject(questionItem)));
                });
                questions.sort((a, b) => a.order! - b.order!);
            }
            this.questions = questions;
        }

    }

    short_title() {
        return (this.title.length > 28) ? this.title.substring(0, 28) + "..." : this.title;
    }

}
