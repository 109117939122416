export class OptionQuestion {
    index: number = 0;
    value: string = "";
    text_color: string = "#000000";
    bg_color: string = "#ffffff";

    constructor(data?: Partial<OptionQuestion>) {
        if (data) {
            Object.assign(this, data);
        }
    }

}
