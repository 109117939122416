import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArraySize } from 'src/app/services/common/arraySize';
import { EmailValidatorDirective } from 'src/app/services/common/emailValidator';



@NgModule({
  declarations: [
    ArraySize,
    EmailValidatorDirective
  ],
  exports: [
    ArraySize,
    EmailValidatorDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
