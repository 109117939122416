import { Validators } from "@angular/forms";

export class Pricing {
    id?: number;
    name?: string;
    type: string = "";
    duration?: number;
    price?: number;
    promo_active: boolean = false;
    promo_value: number = 0;
    price_promo: number = 0;
    plan_id: string = "";
    start_promo: string = "";
    end_promo: string = "";
    features_blocked: Array<string> = [];
    more_features: Array<string> = [];

    constructor(data?: Partial<Pricing>) {
        if (data) {
            Object.assign(this, data);
        }
    }

    static fillData(item: any) {
        let obj = item;
        if (obj.promo_active) {
            if (obj.start_promo != "" && obj.start_promo != null && obj.end_promo != "" && obj.end_promo != null) {
                const now = new Date().getTime();
                const start = new Date(obj.start_promo).getTime();
                const end = new Date(obj.end_promo).getTime();
                if ((start - now) <= 0 && (end - now) >= 0) {
                    let value = 0;
                    if (obj.promo_type == "Pourcentage") {
                        value = obj.price - ((obj.promo_value / 100) * obj.price)
                    } else {
                        value = obj.price - obj.promo_value;
                    }
                    obj["price_promo"] = value.toFixed(2);
                } else {
                    obj.promo_active = false;
                }
            } else {
                obj.promo_active = false;
            }
        }

        return obj;
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;
        obj["price"] = obj.price.toFixed(2);
        if (obj.promo_active) {
            if (obj.start_promo != "" && obj.start_promo != null && obj.end_promo != "" && obj.end_promo != null) {
                const now = new Date().getTime();
                const start = new Date(obj.start_promo).getTime();
                const end = new Date(obj.end_promo).getTime();
                if ((start - now) <= 0 && (end - now) >= 0) {
                    let value = 0;
                    if (obj.promo_type == "Pourcentage") {
                        value = obj.price - ((obj.promo_value / 100) * obj.price)
                    } else {
                        value = obj.price - obj.promo_value;
                    }
                    obj["price_promo"] = value.toFixed(2);
                } else {
                    obj.promo_active = false;
                }
            } else {
                obj.promo_active = false;
            }
        }
        

        return obj;
    }

}
