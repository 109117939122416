import { Profile } from "./profile-model";
import { environment } from 'src/environments/environment';
import { Stripe } from "./stripe-model";
import { AwsService } from "../services/aws/aws.service";

export class User {
  id!: number;
  username!: string;
  password!: string;
  email!: string;
  confirmed!: boolean;
  blocked!: boolean;
  token!: string;
  isEmpty: boolean = true;
  customer_id?: string;
  profile: Profile = new Profile({});
  stripe: Stripe = new Stripe({});
  session_id: string = "";

  constructor(data: Partial<User>) {

    Object.assign(this, data);
    if(data){
      if (data.id !== undefined) {
        this.isEmpty = false;
      }
      if (data.profile !== undefined) {
        this.profile = new Profile(data.profile);
      }
      if (data.stripe !== undefined) {
        this.stripe = new Stripe(data.stripe);
      }
    }
  }

  full_name() {
    return this.profile.first_name + " " + this.profile.last_name;
  }

  static toJson(user: any, jwt: string) {
    if (user.profile) {
      let userJson = {
        id: user.id,
        username: user.username,
        password: "",
        email: user.email,
        confirmed: user.confirmed,
        blocked: user.blocked,
        token: jwt,
        profile: user.profile,
        stripe: user.stripe,
        session_id: user.session_id,
        password_edit_by: user.password_edit_by
      }
      if (!user.profile.avatar) {
        userJson.profile.thumbnail = environment.default_avatar;
      } else {
        userJson.profile.thumbnail = AwsService.generate_link(user.profile.avatar.formats.thumbnail.url);
      }
      return userJson;
    } else {
      return null;
    }

  }

}
