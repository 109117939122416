import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  generateTags(config:any) {
    this.title.setTitle(config.title+" | Le nid it");

    this.meta.updateTag({ name: 'title', content: config.title });
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'image', content: config.image });

    this.meta.updateTag({ property: 'twitter:site', content: environment.url });
    this.meta.updateTag({ property: 'twitter:title', content: config.title });
    this.meta.updateTag({ property: 'twitter:description', content: config.description });
    this.meta.updateTag({ property: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: config.url });
    // Include Schema.org Markup
    const jsonLdScript = {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": config.title,
      "description": config.description,
      // Add other properties as needed
    };
    this.addJsonLdScript(jsonLdScript);
    // canonical tag
    this.updateCanonicalLink(config.url);
  }

  private updateCanonicalLink(url: string) {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', url);
    }
  }

  private addJsonLdScript(data: any) {
    // Remove existing JSON-LD script tag if it exists
    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      existingScript.remove();
    }
  
    // Create and append new JSON-LD script tag
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.textContent = JSON.stringify(data);
    document.head.appendChild(script);
  }
  
}