import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pricing } from 'src/app/models/pricing-model';
import { PricingService } from 'src/app/services/pricing/pricing.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Slider } from 'src/app/models/slider-model';
import { User } from 'src/app/models/user-model';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  @Input() is_connected: boolean = false;
  @Input() currentUser: User = new User({});
  @Input() access_to_course: boolean = true;
  @Input() pricing_selected?: Pricing;
  @Input() gift_sliders: Array<Slider> = [];

  stripe: any;
  elements: any;

  abonnement_type = 'Particulier';

  selected_pricing_list: Array<Pricing> = [];
  list_pricing_from_db: Array<Pricing> = [];
  url_api: string = '';
  loading: boolean = true;

  FormContact!: FormGroup;
  submitFormContact = false;
  active_gift: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private pricingService: PricingService,
    private stripeService: StripeService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.FormContact = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      nbr_licence: ['1 - 10 licences', [Validators.required]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.loadStripe();
    this.url_api = environment.api_base_link;
    this.abonnement_type = this.pricing_selected
      ? this.pricing_selected.type == ''
        ? 'Particulier'
        : this.pricing_selected.type
      : 'Particulier';
    this.get_pricings();

    /*
    if (this.pricingService.pricingsSubject.value != null) {
      this.get_selected_pricing_list();
    } else {
      this.get_pricings();
    }
    */
  }

  async loadStripe() {
    this.stripe = await loadStripe(environment.stripe_pk);
    this.elements = this.stripe.elements();
  }

  get_selected_pricing_list() {
    this.selected_pricing_list =
      this.pricingService.pricingsSubject.value != null
        ? this.pricingService.pricings.filter((element: Pricing) => {
            return element.type == this.abonnement_type;
          })
        : this.list_pricing_from_db.filter((element: Pricing) => {
            return element.type == this.abonnement_type;
          });
  }

  setType(value: string) {
    this.abonnement_type = value;
    //this.get_selected_pricing_list();
  }

  paySubscription(pricing: Pricing) {
    if (this.is_connected) {
      if (this.authService.validate_profile()) {
        var content_html = ``;

        let address = this.currentUser.profile.address;
        let city = this.currentUser.profile.city;
        let country = this.currentUser.profile.country;
        let postal_code = this.currentUser.profile.postal_code;

        var full_address =
          address + ', ' + postal_code + ' ' + city + ', ' + country;

        content_html += `
        <div style="margin-left:20px;margin-right:20px;margin-top:10px;">
           <strong>ADRESSE DE FACTURATION</strong>
           <p>Souhaitez-vous utiliser cette adresse pour la facturation ? Sinon, veuillez la modifier.</p>
           
           <div class="row">
              <div class="col-md-12">
                  <input style="font-weight:bold;color: #007daa;" type="text" value="${address}" class="form-control text-center" placeholder="Adresse" id="pay-billing-address" />
              </div>
           </div>
           <div class="row mt-2">
               <div class="col-md-4">
                   <input style="font-weight:bold;color: #007daa;" type="text" value="${city}" class="form-control text-center" placeholder="Ville" id="pay-billing-city" />
               </div>
               <div class="col-md-4">
                   <input style="font-weight:bold;color: #007daa;" type="text" value="${postal_code}" class="form-control text-center" placeholder="Code postal" id="pay-billing-postal-code" />
               </div>
               <div class="col-md-4">
                   <input style="font-weight:bold;color: #007daa;" type="text" value="${country}" class="form-control text-center" placeholder="Pays" id="pay-billing-country" />
               </div>
           </div>
        </div>
      `;

        if (this.active_gift) {
          content_html += `
        <div style="margin-left:20px;margin-right:20px;margin-top:20px;">
           <strong>ADRESSE DE LIVRAISON</strong>
           <p>Souhaitez-vous utiliser cette adresse pour la réception de votre cadeau ? Sinon, veuillez la modifier.</p>
           
           <input style="font-weight:bold;color: #007daa;" type="text" value="${full_address}" class="form-control text-center" name="address" id="pay-subscription-address" />
        </div>
      `;
        }

        content_html += `
        <div style="margin-left:20px;margin-right:20px;margin-top:30px;">
           <div class="form-check">
             <input class="form-check-input" type="checkbox" id="pay-subscription-option1" name="option1" value="option1" checked>
             <label class="form-check-label" for="pay-subscription-option1">Activer le renouvellement automatique</label>
           </div>
        </div>
        <p style="font-size:14px;margin-top:20px;margin-bottom:30px;">Votre carte sera enregistrée si vous avez choisi le renouvellement automatique.</p>
      `;

        Swal.fire({
          title: '',
          html: content_html,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          confirmButtonText: 'Confirmer',
          focusConfirm: false,
          preConfirm: () => {

            const option1 = (
              document.getElementById(
                'pay-subscription-option1'
              ) as HTMLInputElement
            ).checked;

            //invoice address
            const billingAddressHtml = document.getElementById(
              'pay-billing-address'
            ) as HTMLInputElement | null;
            let billing_address = (billingAddressHtml != null) ? billingAddressHtml.value : '';

            const billingCityHtml = document.getElementById(
              'pay-billing-city'
            ) as HTMLInputElement | null;
            let billing_city = (billingCityHtml != null) ? billingCityHtml.value : '';

            const billingPostalCodeHtml = document.getElementById(
              'pay-billing-postal-code'
            ) as HTMLInputElement | null;
            let billing_postal_code = (billingPostalCodeHtml != null) ? billingPostalCodeHtml.value : '';

            const billingCountryHtml = document.getElementById(
              'pay-billing-country'
            ) as HTMLInputElement | null;
            let billing_country = (billingCountryHtml != null) ? billingCountryHtml.value : '';



            //Shipping address
            const shippingAddressHtml = document.getElementById(
              'pay-subscription-address'
            ) as HTMLInputElement | null;
            let shipping_address = (shippingAddressHtml != null)? shippingAddressHtml.value : '';

            return [option1, shipping_address, billing_address, billing_city, billing_postal_code, billing_country];
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const selectedOptions = result.value;
            if (selectedOptions && selectedOptions.length > 0) {

              let billing_address = selectedOptions[2];
              let billing_city = selectedOptions[3];
              let billing_postal_code = selectedOptions[4];
              let billing_country = selectedOptions[5];

              let shipping_address = selectedOptions[1];
              let cancelAtPeriodEnd = selectedOptions[0] as boolean;
              let saveCard = cancelAtPeriodEnd as boolean;

              this.spinner.show();
              this.stripeService
                .createSubscription({
                  shipping_address: shipping_address,
                  active_gift: this.active_gift,
                  save_card: saveCard,
                  customer:
                    this.authService.currentUserValue.stripe.customer_id,
                  price_id: pricing.plan_id,
                  pricing: pricing.id,
                  cancel_at_period_end: !cancelAtPeriodEnd,
                  billing_info: {
                    billing_fullname: this.currentUser.profile.first_name+' '+this.currentUser.profile.last_name,
                    billing_phone: this.currentUser.profile.phone,
                    billing_address: billing_address,
                    billing_city: billing_city,
                    billing_postal_code: billing_postal_code,
                    billing_country: billing_country
                  }
                })
                .subscribe(
                  (res) => {
                    const token = res.id;
                    if (token != null) {
                      this.stripe.redirectToCheckout({
                        sessionId: token,
                      });
                    } else {
                      if (
                        res.code &&
                        res.code == 'customer_tax_location_invalid'
                      ) {
                        let path = this.getCurrentRoute();
                        Swal.fire(
                          'Attention',
                          "veuillez compléter votre profil 'adresse'",
                          'info'
                        );
                        this.router.navigate(['/mon-profil'], {
                          queryParams: { return: path },
                        });

                        /*
                    this.activatedRoute.url.pipe(
                      map(segments => segments.join('/'))
                    ).subscribe((value) => {
                      Swal.fire('Attention', "veuillez compléter votre profil 'adresse'", 'info');
                      this.router.navigate(['/mon-profil'], { queryParams: { "return": value } });
                    });
                    */
                      } else {
                        Swal.fire('Erreur', 'Une érreur est survenue', 'error');
                      }
                    }
                  },
                  (err) => {
                    Swal.fire('Erreur', 'Une érreur est survenue', 'error');
                  }
                )
                .add(() => {
                  this.spinner.hide();
                });
            }
          }
        });
      } else {
        /*
        this.activatedRoute.url.pipe(
          map(segments => segments.join('/'))
        ).subscribe((value) => {
          Swal.fire('Attention', "veuillez compléter votre profil", 'info');
          this.router.navigate(['/mon-profil'], { queryParams: { "return": value } });
        });
        */
        let path = this.getCurrentRoute();
        Swal.fire('Attention', 'veuillez compléter votre profil', 'info');
        this.router.navigate(['/mon-profil'], {
          queryParams: { return: path },
        });
      }
    } else {
      Swal.fire({
        title: 'Attention',
        text: 'Vous devez être connecté pour souscrire un abonnement',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `S'inscrire`,
        cancelButtonText: `Se connecter`,
        cancelButtonColor: 'blue',
      }).then((result) => {
        let path = this.getCurrentRoute();
        if (result.isConfirmed) {
          this.router.navigate(['/register'], {
            queryParams: { return: path },
          });
        } else {
          this.router.navigate(['/login'], { queryParams: { return: path } });

          /*

          this.activatedRoute.url.pipe(
            map(segments => segments.join('/'))
          ).subscribe((value) => {
            //this.router.navigate(['/login'], { queryParams: { "return": value } });
          });
          */
        }
      });
    }
  }

  getCurrentRoute() {
    let path = this.router.url;
    if (path.startsWith('/')) {
      path = path.substring(1);
    }
    return path;
  }

  manage() {
    this.spinner.show();
    this.stripeService
      .manageBilling()
      .subscribe(
        (res) => {
          window.open(res.url, '_blank');
        },
        (err) => {
          console.log(err);
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  get_pricings() {
    this.pricingService
      .get()
      .subscribe((res: any) => {
        let pricings: Array<Pricing> = [];
        res.data.forEach((item: any) => {
          pricings.push(new Pricing(Pricing.attributeToJson(item)));
        });

        this.list_pricing_from_db = pricings;
        this.selected_pricing_list = pricings.filter((element: Pricing) => {
          return element.type == this.abonnement_type;
        });
      })
      .add(() => {
        this.loading = false;
      });
  }

  mes_abonnements() {
    this.router.navigate(['/mes-abonnements']);
  }

  get contact() {
    return this.FormContact.controls;
  }

  onSubmit() {
    this.submitFormContact = true;
    this.markFormGroupTouched(this.FormContact);

    if (this.FormContact.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html:
          '<p>Veuillez vérifier vos informations</p>' +
          (this.FormContact.get('email')?.invalid &&
          this.FormContact.get('email')?.touched
            ? "<p>Le format de l'adresse email n'est pas valide</p>"
            : ''),
        confirmButtonText: 'OK',
      });
      return;
    }
    this.spinner.show();
    this.pricingService
      .send_email(this.FormContact.value)
      .subscribe(
        (res) => {
          Swal.fire('Envoyé', 'votre message a bien été envoyé', 'success');
          this.FormContact.reset();
          this.submitFormContact = false;
        },
        (err) => {}
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  paySubscriptionTest() {
    if (this.is_connected) {
      if (this.authService.validate_profile()) {
        Swal.fire({
          title: '',
          html: `
              <div style="margin-left:20px;margin-right:20px;margin-top:30px;">
                 <div class="form-check">
                   <input class="form-check-input" type="checkbox" id="pay-subscription-option1" name="option1" value="option1" checked>
                   <label class="form-check-label" for="pay-subscription-option1">Activer le renouvellement automatique</label>
                 </div>
              </div>
              <p style="font-size:14px;margin-top:20px;margin-bottom:30px;">Votre carte sera enregistrée si vous avez choisi le renouvellement automatique.</p>
            `,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          confirmButtonText: 'Confirmer',
          focusConfirm: false,
          preConfirm: () => {
            const option1 = (
              document.getElementById(
                'pay-subscription-option1'
              ) as HTMLInputElement
            ).checked;
            return [option1];
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const selectedOptions = result.value;
            if (selectedOptions && selectedOptions.length > 0) {
              let cancelAtPeriodEnd: boolean = selectedOptions[0];
              let saveCard: boolean = cancelAtPeriodEnd;

              this.spinner.show();
              this.stripeService
                .createSubscription({
                  save_card: saveCard,
                  customer:
                    this.authService.currentUserValue.stripe.customer_id,
                  price_id: environment.price_stripe_for_test,
                  pricing: '1',
                  cancel_at_period_end: !cancelAtPeriodEnd,
                })
                .subscribe(
                  (res) => {
                    const token = res.id;
                    if (token != null) {
                      this.stripe.redirectToCheckout({
                        sessionId: token,
                      });
                    } else {
                      if (
                        res.code &&
                        res.code == 'customer_tax_location_invalid'
                      ) {
                        let path = this.getCurrentRoute();
                        Swal.fire(
                          'Attention',
                          "veuillez compléter votre profil 'adresse'",
                          'info'
                        );
                        this.router.navigate(['/mon-profil'], {
                          queryParams: { return: path },
                        });

                        /*
                  this.activatedRoute.url.pipe(
                    map(segments => segments.join('/'))
                  ).subscribe((value) => {
                    Swal.fire('Attention', "veuillez compléter votre profil 'adresse'", 'info');
                    this.router.navigate(['/mon-profil'], { queryParams: { "return": value } });
                  });
                  */
                      } else {
                        Swal.fire('Erreur', 'Une érreur est survenue', 'error');
                      }
                    }
                  },
                  (err) => {
                    Swal.fire('Erreur', 'Une érreur est survenue', 'error');
                  }
                )
                .add(() => {
                  this.spinner.hide();
                });
            }
          }
        });
      } else {
        Swal.fire('Attention', 'veuillez compléter votre profil', 'info');
        this.router.navigate(['/mon-profil'], {
          queryParams: { return: 'tarification' },
        });
      }
    } else {
      Swal.fire({
        title: 'Attention',
        text: 'Vous devez être connecté pour souscrire un abonnement',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `S'inscrire`,
        cancelButtonText: `Se connecter`,
        cancelButtonColor: 'blue',
      }).then((result) => {
        let path = this.getCurrentRoute();
        if (result.isConfirmed) {
          this.router.navigate(['/register'], {
            queryParams: { return: path },
          });
        } else {
          this.router.navigate(['/login'], { queryParams: { return: path } });

          /*
          this.activatedRoute.url.pipe(
            map(segments => segments.join('/'))
          ).subscribe((value) => {
            this.router.navigate(['/login'], { queryParams: { "return": value } });
          });
          */
        }
      });
    }
  }
}
