
export class Stripe {
    id!: number;
    customer_id!: string;

    constructor(data: Partial<Stripe>) {
        Object.assign(this, data);
    }

}
