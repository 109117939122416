<div class="widget-sidebar ml-15">
    <div class="sidebar-widget search">
        <h3>Search</h3>
        <form class="search-form">
            <input class="form-control" name="search" placeholder="Search..." type="text">
            <button class="search-button" type="submit"><i class="ri-search-line"></i></button>
        </form>	
    </div>
    <div class="sidebar-widget categories">
        <h3>Categories</h3>
        <ul>
            <li>
                <a routerLink="/blog">
                    Business
                    <span>(7)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Human Resources
                    <span>(2)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Investment
                    <span>(4)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Lifestyle
                    <span>(7)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    English
                    <span>(9)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Marketing
                    <span>(3)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Management
                    <span>(6)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Video
                    <span>(1)</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="sidebar-widget recent-post">
        <h3 class="widget-title">Popular Posts</h3>
        <ul>
            <li>
                <a routerLink="/blog-details">
                    Top Workplace Learning News
                    <img src="assets/images/blogs/recent-news-1.jpg" alt="Image">
                </a>
                <span><i class="ri-calendar-2-line"></i>November 03, 2021</span>
            </li>
            <li>
                <a routerLink="/blog-details">
                    How To Become A Writer
                    <img src="assets/images/blogs/recent-news-2.jpg" alt="Image">
                </a>
                <span><i class="ri-calendar-2-line"></i>November 02, 2021</span>
            </li>
            <li>
                <a routerLink="/blog-details">
                    Data Science Importance Rules
                    <img src="assets/images/blogs/recent-news-3.jpg" alt="Image">
                </a>
                <span><i class="ri-calendar-2-line"></i>November 01, 2021</span>
            </li>
        </ul>
    </div>
    <div class="sidebar-widget tags mb-0">
        <h3>Popular Tags</h3>
        <ul>
            <li><a routerLink="/blog">Business</a></li>
            <li><a routerLink="/blog">Data science</a></li>
            <li><a routerLink="/blog">Education</a></li>
            <li><a routerLink="/blog">Video</a></li>
            <li><a routerLink="/blog">Language</a></li>
            <li><a routerLink="/blog">Investment</a></li>
            <li><a routerLink="/blog">Learning</a></li>
            <li><a routerLink="/blog">English</a></li>
            <li><a routerLink="/blog">Web development</a></li>
        </ul>
    </div>
</div>