import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user-model';
import { Profile } from 'src/app/models/profile-model';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CategoryService } from 'src/app/services/cateogry/category.service';
import { Category } from 'src/app/models/category-model';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormationService } from 'src/app/services/formation/formation.service';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Setting } from 'src/app/models/setting-model';
import { SettingService } from 'src/app/services/setting/setting.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartService } from 'src/app/services/cart/cart.service';
import { Formation } from 'src/app/models/formation-model';
import { AuthAdminService } from 'src/app/services/auth-admin/auth-admin.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  searchForm: FormGroup;
  currentUser: User = new User({});
  settings!: Setting;
  show_settings: boolean = false;
  categories: Array<Category> = [];
  switcherClassApplied = false;
  classApplied = false;
  loading = true;
  formatter = (result: any) => result.title;
  formations: any = [];
  time_left_value: string = '';
  someInterval: any;
  loading_settings: boolean = true;
  isHidden: boolean = true;
  cartItems: Formation[] = [];
  totalPrice: number = 0;

  constructor(
    private ngZone: NgZone,
    public router: Router,
    private authService: AuthService,
    private authAdminService:AuthAdminService,
    private categoryService: CategoryService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private formationService: FormationService,
    private cartService: CartService
  ) {
    this.searchForm = this.formBuilder.group({
      searchInput: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    // load cart items
    this.cartService.cart$.subscribe((items) => {
      this.formationService.getCartItems(items).subscribe(res => {
        this.cartItems = res.map((formation: any) => new Formation(formation))
        this.totalPrice = this.cartItems.reduce((sum, item) => sum + ((item.has_promo) ? item.new_price : item.price), 0);
      }).add(() => {
        this.loading = false;
      })
    });

    this.authService.currentUserSubject.subscribe((user) => {
      this.currentUser = new User(user);
      this.spinner.hide();
      this.loading = false;
    });

    /*
        if (this.categoryService.categoriesSubject.value != null) {
            this.categories = this.categoryService.categories;
        } else {
            this.get_categories_light();
        }
        if(this.settingService.settingSubject.value != null){
            if (this.settingService.settings.show == "1") {
                this.init_settings(this.settingService.settings.value);
            }
        }else{
            this.get_settings();
        }
        */
    this.get_categories_light();
    this.get_settings();

    this.switcherClassApplied = false;
  }

  init_settings(values: any) {
    this.settings = values;

    this.calcule_dates();
    if (this.show_settings) {
      this.ngZone.runOutsideAngular(() => {
        this.someInterval = setInterval(() => {
          this.calcule_dates();
        }, 60000);
      });
    }
  }

  get_settings() {
    this.settingService
      .get()
      .subscribe((res) => {
        this.init_settings(new Setting(Setting.attributeToJson(res.data)));
      })
      .add(() => {
        this.loading_settings = false;
      });
  }

  calcule_dates() {
    const now = new Date().getTime();
    const start_top_bar = new Date(this.settings.start_top_bar).getTime();
    const end_top_bar = new Date(this.settings.end_top_bar).getTime();
    if (start_top_bar - now <= 0 && end_top_bar - now >= 0) {
      this.show_settings = true;

      let diffMs = end_top_bar - now;
      let diffDays = Math.floor(diffMs / 86400000);
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000);
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

      this.time_left_value =
        diffDays + ' jours, ' + diffHrs + ' heures, ' + diffMins + ' minutes';
    }
  }

  logout() {
    this.authService.logout();
    this.classApplied = false;
    this.switcherToggleClass();
  }

  switcherToggleClass() {
    this.switcherClassApplied = !this.switcherClassApplied;
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  toggleCartDescription() {
    this.isHidden = !this.isHidden;
  }

  goToSearchPage() {
    this.router
      .navigate(['/recherche'], {
        queryParams: { q: this.f.searchInput.value },
      })
      .then(() => {
        this.formations = [];
        this.searchForm = this.formBuilder.group({
          searchInput: ['', [Validators.required]],
        });
      });
  }

  get f() {
    return this.searchForm.controls;
  }

  search_formations(value: string) {
    this.formationService
      .get_light(
        5,
        1,
        '&filters[profile][id][$notNull]=true&filters[is_draft][$eq]=false&filters[title][$contains]=' +
        value
      )
      .subscribe(
        (res: any) => {
          this.formations = [];
          res.data.forEach((item: any) => {
            this.formations.push(item.attributes);
          });
        },
        (err) => {
          console.log(err);
        }
      );
    return this.formations;
  }

  select(term: any) {
    this.router.navigate(['/courses-details/' + term.item.slug]).then(() => {
      this.formations = [];
      this.searchForm = this.formBuilder.group({
        searchInput: ['', [Validators.required]],
      });
    });
  }

  setBgTopBar() {
    let styles = {
      'background-color': this.settings.bg_top_bar,
    };
    return styles;
  }

  setMsgTopBar() {
    let styles = {
      color: this.settings.color_top_bar,
      'font-size': '16px',
    };
    return styles;
  }

  closeTopBar() {
    this.settingService.close_settings();
    this.show_settings = false;
  }

  search: OperatorFunction<string, any> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => this.search_formations(term))
    );
  };

  get_categories_light() {
    this.categoryService.get_light().subscribe((res) => {
      this.categories = [];
      res.data.forEach((item: any) => {
        this.categories.push(new Category(Category.attributeToJson(item)));
      });
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  desconnect_admin() {
    this.authAdminService.logout();
  }
}
